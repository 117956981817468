import React, { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';

import './MonthlyPaymentCalculator.css';
import Icon1 from '../../public/cal1.png';
import Icon2 from '../../public/cal2.png';
import Icon3 from '../../public/cal3.png';
import Icon4 from '../../public/cal4.png';

/* Range-slider */
function handleSliderProgress(e) {
    const thumbWidthPercentage = (20 / e.target.offsetWidth) * 100; 
    const value = ((e.target.value - e.target.min) / (e.target.max - e.target.min) * 100) - (thumbWidthPercentage / 2);
    e.target.style.setProperty('--percent', `${value}%`);
}
/* Range-slider */

function MonthlyPaymentCalculator() {
    const [interestRate, setInterestRate] = useState(5);
    const [loanTerm, setLoanTerm] = useState(2);
    const [propertyCost, setPropertyCost] = useState(100000);
    const [downPayment, setDownPayment] = useState(10);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    

    useEffect(() => {
        if(interestRate && loanTerm && propertyCost && downPayment) {
            const monthlyInterestRate = interestRate / 12 / 100;
            const n = loanTerm * 12; 
            const principal = propertyCost * (1 - (downPayment / 100));

            const payment = (principal * monthlyInterestRate) / (1 - Math.pow((1 + monthlyInterestRate), -n));
            setMonthlyPayment(payment);
        }
    }, [interestRate, loanTerm, propertyCost, downPayment]);

    return (
        <div className="calculator-wrapper">
            <h2 className="features_all">Estimated Monthly Payment</h2>
            <div className="calculation_zone">
                <div className="top_calc">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-md-12">
                            <label htmlFor="interestRate">Interest Rate, %</label>
                            <input
                                type="text"
                                className="form-control"
                                id="interestRate"
                                value={interestRate}
                                onChange={e => setInterestRate(parseFloat(e.target.value))}
                            />
                         


<ReactSlider
                        className="horizontal-slider"
                        thumbClassName="thumb"
                        trackClassName="track"
                        min={0}
                        max={100}
                        step={0.1}
                        value={interestRate}
                        onChange={value => setInterestRate(value)}
                    />




                        </div>
                        <div className="col-lg-6 col-sm-12 col-md-12 ppt">
                            <label htmlFor="loanTerm">Loan Term, Years</label>
                            <input
                                type="text"
                                className="form-control"
                                id="loanTerm"
                                value={loanTerm}
                                onChange={e => setLoanTerm(parseInt(e.target.value, 10))}
                            />
                        <ReactSlider
    className="horizontal-slider"
    thumbClassName="thumb"
    trackClassName="track"
    min={0}
    max={30}
    step={1}
    value={loanTerm}
    onChange={value => setLoanTerm(value)}
/>

                        </div>
                    </div>
                    <div className="row hmtp ">
                        <div className="col-lg-6 col-sm-12 col-md-12">
                            <label htmlFor="propertyCost">Property Cost</label>
                             <input
                                type="text"
                                className="form-control"
                                id="propertyCost"
                                value={propertyCost}
                                onChange={e => setPropertyCost(parseFloat(e.target.value))}
                            />
                           
                           <ReactSlider
    className="horizontal-slider"
    thumbClassName="thumb"
    trackClassName="track"
    min={0}
    max={1000000}
    step={1000}
    value={propertyCost}
    onChange={value => setPropertyCost(value)}
/>

                        </div>
                        <div className="col-lg-6 col-sm-12 col-md-12 dpay">
    <label htmlFor="downPayment">Down Payment %</label>
    <input
        type="text"
        className="form-control"
        id="downPayment"
        value={downPayment}
        onChange={e => setDownPayment(parseFloat(e.target.value, 10))}
    />
    <div className="dpay_amount">$ {(downPayment / 100 * propertyCost).toFixed(2)}</div>
    <ReactSlider
    className="horizontal-slider"
    thumbClassName="thumb"
    trackClassName="track"
    min={0}
    max={100}
    step={0.1}
    value={downPayment}
    onChange={value => setDownPayment(value)}
/>

</div>
                    </div>
                </div>

                <div className="bottom_calc">
                    <div className="row mt-4">
                        <div className="col-lg-5 offset-lg-1">
                            <div className="d-flex align-items-center tt">
                                <span className="icon-placeholder mr-2"><img src={Icon1} alt="calc1" /></span>
                                <div>
                                    <div className="font-weight-bold">{interestRate.toFixed(2)}%</div>
                                    <small>Interest Rate</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="d-flex align-items-center tt">
                                <span className="icon-placeholder mr-2"><img src={Icon2} alt="calc2" /></span>
                                <div>
                                    <div className="font-weight-bold">${(propertyCost * (1 - (downPayment / 100))).toFixed(2)}</div>
                                    <small>Loan Amount</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-6">
                        <div className="col-lg-5 offset-lg-1">
                            <div className="d-flex align-items-center tt huf">
                                <span className="icon-placeholder mr-2"><img src={Icon3} alt="calc3" /></span>
                                <div>
                                    <div className="font-weight-bold">{loanTerm}</div>
                                    <small>Period (Years)</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="d-flex align-items-center tt">
                                <span className="icon-placeholder mr-2"><img src={Icon4} alt="calc4" /></span>
                                <div>
                                    <div className="font-weight-bold">${monthlyPayment.toFixed(2)}</div>
                                    <small>Monthly Payment</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MonthlyPaymentCalculator;
