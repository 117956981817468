import React, { useState } from 'react';
import './Navigation.css';

import Username from '../../public/assets/login2.svg'
import Password from '../../public/assets/login1.svg'
import Facebook from '../../public/assets/fb.svg'
import LinkedIn from '../../public/assets/link.svg'
import Twitter from '../../public/assets/x.svg'
import Google from '../../public/assets/gplus.svg'
import Phones from '../../public/assets/phone.svg'
import Message from '../../public/assets/message.svg'
import Cross from '../../public/assets/cross.svg'
import Otp from '../../public/assets/otp.svg'
import Success from '../../public/assets/success.png'


function LoginProcessModal(props) {
  const [activeTab, setActiveTab] = useState('login');

   /*  forgot password-flow */
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordStage, setForgotPasswordStage] = useState('initial');


  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setShowForgotPassword(true);
    setForgotPasswordStage('sendOTP');
  };

  const handleSentOTPClick = (e) => {
    e.preventDefault();
    setForgotPasswordStage('enterOTP');
  };
  
  const handleGetOTPClick = (e) => {
    e.preventDefault();
    setForgotPasswordStage('changePassword');
  };
  
  const handleSavePasswordClick = (e) => {
    e.preventDefault();
    setForgotPasswordStage('passwordChanged');
  };

 /*  forgot password-flow */



  
  const handleBackToLogin = (e) => {
    e.preventDefault();
    setShowForgotPassword(false);
  };


  return (
    <div 
      className="modal fade" 
      id="loginModal" 
      tabIndex="-1" 
      aria-labelledby="exampleModalLabel" 
      aria-hidden="true" 
      data-backdrop="static" 
      data-keyboard="false"
    >
      <div className="modal-dialog custom-modal-width modal-dialog-centered">
        <div className="modal-content">
       <div className="modal_closer"><img src={Cross} alt="User Icon" data-bs-dismiss="modal" aria-label="Close" /></div>
        

          <div className="modal-body">
           <div className="full_modal">
            <div className="left_modal">
            {!showForgotPassword && (
      <div className="tab-navigation">
        <button 
          onClick={() => setActiveTab('login')}
          className={`tab-button ${activeTab === 'login' ? 'active' : ''}`}>
          Login
        </button>
        <button 
          onClick={() => setActiveTab('signup')}
          className={`tab-button ${activeTab === 'signup' ? 'active' : ''}`}>
          Sign up
        </button>
      </div>
    )}

{activeTab === 'login' && !showForgotPassword && (        
<div className="tab-content">
         
         <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text bg_nol" id="basic-addon1">
                                <img src={Username} alt="User Icon" />
                            </span>
                            <input type="text" className="form-control" placeholder="Name*" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text bg_nol" id="basic-addon2">
                                <img src={Password} alt="Password Icon" />
                            </span>
                            <input type="password" className="form-control" placeholder="Password*" />
                        </div>
                    </div>
                    <div className="mb-3 d-flex justify-content-end fgt">
                    <a href="#" onClick={handleForgotPasswordClick} className="text-decoration-none">Forgot Password?</a>
                    </div>
                    <button className="btn login_btn">Login</button>
                    <p className="text-center or_login">Or Login with</p>
                    {/* Add social buttons here */}
               

                </div>
      )}


{/* forgot-password-flow */}

{showForgotPassword && forgotPasswordStage === 'sendOTP' && (
      <div className="forgot-password-content">
        <h2>Forgot Password</h2>
        <p>Enter your register email to get OTP</p>
       
        <div className="mb-3 fpass">
                       <div className="input-group">
                          
                           <span className="input-group-text bg_nol" id="basic-addon1">
                               <img src={Message} alt="User Icon" />
                           </span>
                           <input type="text" className="form-control" placeholder="Email*" />
                       </div>
                   </div>
                   <button className="btn login_btn" onClick={handleSentOTPClick}>Sent OTP</button>

                   <p className="text-center or_login">  <a href="#" onClick={handleBackToLogin}>Login</a> with password or</p>

      
      </div>
    )}



{showForgotPassword && forgotPasswordStage === 'enterOTP' && (
      <div className="forgot-password-content">
        <h2>Get Otp</h2>
        <p>Write OTP to change the password, OTP send on mail. </p>
       
        <div className="mb-3 fpass">
                       <div className="input-group">
                          
                           <span className="input-group-text bg_nol" id="basic-addon1">
                               <img src={Otp} alt="otp" />
                           </span>
                           <input type="text" className="form-control" placeholder="OTP*" />
                       </div>
                   </div>
                   <button className="btn login_btn" onClick={handleGetOTPClick}>Get OTP</button>

                   <p className="text-center or_login">  <a href="#" onClick={handleBackToLogin}>Login</a> with password or</p>

      
      </div>
    )}



{showForgotPassword && forgotPasswordStage === 'changePassword' && (
      <div className="forgot-password-content">
        <h2>Change Password</h2>
        <p>Make sure to use a strong password. </p>
       
        <div className="mb-3 fpass">
                        <div className="input-group">
                            <span className="input-group-text bg_nol" id="basic-addon2">
                                <img src={Password} alt="Password Icon" />
                            </span>
                            <input type="password" className="form-control" placeholder="Write password**" />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text bg_nol" id="basic-addon3">
                                <img src={Password} alt="Password Icon" />
                            </span>
                            <input type="password" className="form-control" placeholder="Write again**" />
                        </div>
                    </div>
                    <button className="btn login_btn" onClick={handleSavePasswordClick}>Save</button>
                   <p className="text-center or_login">  <a href="#" onClick={handleBackToLogin}>Login</a> with password or</p>

      
      </div>
    )}

{showForgotPassword && forgotPasswordStage === 'passwordChanged' && (
  <div className="forgot-password-content">
    <h2>Password Changed Successfully!</h2>
    <div className="successful"> <img src={Success} alt="Password Icon" /></div>
    <p className="scful">You can now <a href="#" onClick={handleBackToLogin}>Login</a> with your new password.</p>
  </div>
)}
    {/* forgot-password-flow */}



      {activeTab === 'signup' && (
        <div className="tab-content">
         
        <div className="mb-3">
                       <div className="input-group">
                          
                           <span className="input-group-text bg_nol" id="basic-addon4">
                               <img src={Username} alt="User Icon" />
                           </span>
                           <input type="text" className="form-control" placeholder="Name*" />
                       </div>
                   </div>
                   <div className="mb-3">
                       <div className="input-group">
                          
                           <span className="input-group-text bg_nol" id="basic-addon5">
                               <img src={Message} alt="User Icon" />
                           </span>
                           <input type="text" className="form-control" placeholder="Email*" />
                       </div>
                   </div>
                   <div className="mb-3">
                       <div className="input-group">
                          
                           <span className="input-group-text bg_nol" id="basic-addon6">
                               <img src={Phones} alt="User Icon" />
                           </span>
                           <input type="number" className="form-control" placeholder="Phone*" />
                       </div>
                   </div>
                  
                   <div className="mb-3">
                       <div className="input-group">
                         
                           <span className="input-group-text bg_nol" id="basic-addon7">
                               <img src={Password} alt="Password Icon" />
                           </span>
                           <input type="password" className="form-control" placeholder="Password*" />
                       </div>
                   </div>
                 
                   <button className="btn login_btn">Signup</button>
                   <p className="text-center or_login">Or connect with</p>
         

               </div>
      )}
                <div className="justify-content-between target_urls">
                     <div className="sbtn">
                   <a href="https://www.facebook.com/login/" target="_blank">
   <button className="custom-btn">
   <img src={Facebook} alt="Facebook" /> Continue with Facebook
   </button>
</a></div>
<div className="sbtn">
                   <a href="https://www.linkedin.com/login" target="_blank">
   <button className="custom-btn">
   <img src={LinkedIn} alt="LinkedIn" /> Continue with LinkedIn
   </button>
</a></div>
<div className="sbtn">
                   <a href="https://twitter.com/login" target="_blank">
   <button className="custom-btn">
   <img src={Twitter} alt="twiteter" /> Continue with Twitter
   </button>
</a></div>
<div className="sbtn">
                   <a href="https://www.google.com/accounts/Login" target="_blank">
   <button className="custom-btn">
   <img src={Google} alt="Google Plus" /> Continue with Google
   </button>
</a></div>

   
</div>
    </div>
           <div className="right_modal">
            <h2>Let's Work Together!</h2>
            <p>By registering on the site you will have full access to up-to-date and accurate MLS listing information. Save the searches you perform or save your favorite listings so you can be notified when new listings hit the market or a listing price changes!</p>
            <p>Looking for market information so you can get the best value for your property? Check out our FREE market report tool which will provide immediate market details using MLS data.</p>
            <p>Your contact information is never shared or distributed.</p>
            <div className="foot_info">
            By signing up you agree to our <a>Terms and Privacy Policy</a>
Protected by reCAPTCHA. <a>Privacy</a> • <a>Terms</a>
            </div>
           </div>
           </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginProcessModal;



