
import React from 'react';
import './PropertyListing.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import listinglogo from '../../../public/logo.png';



function PropertyListing() {
    return (
      <div className="container properties_listing">



<div id="propertyCarousel" className="carousel slide">
       
            <div className="carousel-inner">
                <div className="carousel-item active">
                    
                <div className="row">
              
                <div className="col-lg-4 col-md-4">
                <Link to="/property-detail" className="property-link"> 
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property1.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
                  </Link>
                </div>
               
                <div className="col-md-4">
             {/*   <Link to="/property-details" className="property-link">  */}
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property2.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
                 {/*  </Link>  */}
                </div>
                <div className="col-md-4">
               {/*  <Link to="/property-details" className="property-link"> */}
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property3.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
                 {/*  </Link> */}
                </div>
              </div>



                </div>
                <div className="carousel-item">
                <div className="row">
                <div className="col-md-4">
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property2.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
              {/*     </Link> */}
                </div>
                <div className="col-md-4">
                {/* <Link to="/property-details" className="property-link"> */}
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property1.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
                 {/*  </Link> */}
                </div>
                <div className="col-md-4">
               {/*  <Link to="/property-details" className="property-link"> */}
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property3.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
                 {/*  </Link> */}
                </div>
              </div>
                </div>
                <div className="carousel-item">
                <div className="row">
                <div className="col-md-4">
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property2.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
              {/*     </Link> */}
                </div>
                <div className="col-md-4">
                {/* <Link to="/property-details" className="property-link"> */}
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property1.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
                 {/*  </Link> */}
                </div>
                <div className="col-md-4">
               {/*  <Link to="/property-details" className="property-link"> */}
                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property1.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
                 {/*  </Link> */}
                </div>
              </div>
                </div>
            </div>

<div className="slider-caros d-flex">
<div className="left_caro" data-bs-target="#propertyCarousel" data-bs-slide="prev">  <i className="fa fa-caret-left"></i></div>
<div className="middle_dots">   
              <ul>
                <li data-bs-target="#propertyCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></li>
                <li data-bs-target="#propertyCarousel" data-bs-slide-to="1" aria-label="Slide 2"></li>
                <li data-bs-target="#propertyCarousel" data-bs-slide-to="2" aria-label="Slide 3"></li>
              </ul>
        </div>
<div className="left_caro right_caro" data-bs-target="#propertyCarousel" data-bs-slide="next">  <i className="fa fa-caret-right"></i></div>
    
</div>

            
          
        </div>


        <div className="row">  
        <div className="col-lg-12"> 
        <div className="mutual_btn listing_btn"><a href="#">More Listing</a>  </div>
       
        </div>
        </div>
      </div>
    );
  }
  
  
  export default PropertyListing;