import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../AboutusPages/AboutUsPages.css';
import Arrow from '../../../public/assets/arrows.svg';

import Agent from '../../../public/assets/agent.png';
import Agent1 from '../../../public/assets/agent1.png';
import Agent3 from '../../../public/assets/agent3.png';
import Agent4 from '../../../public/assets/agent4.png';

function AgentPage() {
    const [searchTerm, setSearchTerm] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const agentsPerPage = 20;

    // Function to generate mock agents data
    const generateMockAgents = () => {
        const mockAgents = [];
        for (let i = 0; i < 100; i++) {
            mockAgents.push({
                name: `Agent Name ${i + 1}`,
                role: "Real Estate Agent, Country House Real Estate",
                image: [Agent, Agent1, Agent3, Agent4][i % 4], 
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus porta justo eget risus consectetur,..."
            });
        }
        return mockAgents;
    };

    // Use the mock agents data
    const agentsData = generateMockAgents();

    const indexOfLastAgent = currentPage * agentsPerPage;
    const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
    const currentAgents = agentsData.slice(indexOfFirstAgent, indexOfLastAgent);

    const totalPages = Math.ceil(agentsData.length / agentsPerPage);

    return (
        <div className="agent_list all_agents">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Meet Our Agents</h1>
                        <div className="d-flex search_count">
                            <div className="agent_search">
                               <input 
    type="search" 
    className="form-control" 
    placeholder="Search Agent by Name, Location, Property Name etc." 
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
/>
                            </div>
                            <div className="agents_count">
    <span className="ml">
        {indexOfFirstAgent + 1}
        {agentsData.length > 1 ? `-${Math.min(indexOfLastAgent, agentsData.length)}` : ''}
    </span> 
    of <span>{agentsData.length}</span> Results
</div>

                        </div>

                        <div className="overall_agents d-flex">
                        {currentAgents.map(agent => (
    <div className="agent_one" key={agent.name}>
        <img src={agent.image} className="img-fluid" alt="agents" />
        <h3>{agent.name}</h3>
        <h4>{agent.role}</h4>
        <p>{agent.description}</p>
        <Link className="view_agent" to="/single-agent">View Profile</Link>
    </div>
))}
                        </div>

                        <div className="pagination">
                        <button className={`left_awo ${currentPage <= 1 ? 'disabled-btn' : ''}`} disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)}>
    <img src={Arrow} alt="Left Arrow" />
</button>

    
    {[...Array(totalPages)].map((_, i) => {
        if(i === 0 || i === totalPages - 1 || (i < 5 && currentPage < 6) || (i < currentPage + 2 && i > currentPage - 2) || (i > totalPages - 5 && currentPage > totalPages - 5)) {
            return (
                <button 
                    key={i} 
                    onClick={() => setCurrentPage(i + 1)}
                    className={currentPage === i + 1 ? 'active' : ''}
                >
                    {i + 1}
                </button>
            )
        } else if(i === 5 && currentPage < 6) {
            return <span key={i}>...</span>
        } else if(i === currentPage + 2) {
            return <span key={i}>...</span>
        } else if(i === totalPages - 5 && currentPage > totalPages - 5) {
            return <span key={i}>...</span>
        }
        return null;
    })}
    
   
    <button className={`${currentPage >= totalPages ? 'disabled-btn' : ''}`} disabled={currentPage >= totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
    <img src={Arrow} alt="Right Arrow" /> 
</button>
</div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgentPage;
