import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BlogBanner from '../../public/assets/blogs/blog-banner.png';
import Clock from '../../public/assets/blogs/clock.svg';
import Angle from '../../public/assets/blogs/angle1.svg';


import '../Blogs/Blogs.css';




function SingleBlog() {

   // State for previous and next blog data
   const [previousBlog, setPreviousBlog] = useState(null);
   const [nextBlog, setNextBlog] = useState(null);

   useEffect(() => {
    // Fetch data from your API or local data source
   
    fetchPreviousAndNextBlogs();
}, []);

const fetchPreviousAndNextBlogs = () => {
    // Here I'm setting some static data for demonstration
    setPreviousBlog({
        title: "Navigating the Competitive Real Estate Market",
        link: "/previous-blog-link"
    });
    setNextBlog({
        title: "Understanding the Mortgage Process",
        link: "/next-blog-link"
    });
};



    return (
        <div className="blog_single">
         <div className="blog_banner"><img src={BlogBanner} className="img-fluid" alt="Blog-Banner"/></div>
         <div className="container">
         <div className="row">
         <div className="col-lg-12">
<div className="single_data">
    <h1>Is Buying a Home Still a Smart Plan</h1>

    <div className="date_time">
<span><img src={Clock} className="img-fluid" alt="time"/></span> 12:30pm Mar 26, 2023

    </div>

    <p>With the burst of the housing bubble, credit crisis, and millions of foreclosures across the country, you may wonder if buying a home is such a good idea after all. However, it’s important to consider all of the facts. The important message to take away from these events is not that buying a home is a bad idea, but that you must be smart about buying your home.</p>

    <p>The housing market, like every type of market, unavoidably has its ups and downs. That doesn’t mean buying a home is a bad investment. As a long-term investment, homeownership is still one of the best investments for individual households. Historically, real estate has consistently increased in value, despite shorter periods of depreciation due to local markets and/or national economic conditions. The data shows that homes generally appreciate about 5% per year.
        </p>

        <h2>Savings & Investment</h2>
        <p>Five percent may not seem like a great return on investment, but you have to think about it in the context of the situation. For example, let’s say you put 10% down on a $200,000 house. That’s a $20,000 down payment, or initial investment. At a 5% annual appreciation rate, your $200,000 home would gain $10,000 in value during the first year. Earning $10,000 on an investment of $20,000 is a whopping 50% return.</p>
        <p>For further perspective, let’s say instead of spending that $20,000 on a down payment, you invested it in the stock market. With a 5% return, you would gain only $1,000 in profit.</p>
        <h2>Tax Benefits</h2>
        <p>So now you’re saying that a home may have a higher return, but that’s before you consider all of the costs of home ownership, such as taxes, etc. Well, think of it this way: your property taxes as well as the interest on your mortgage are both tax deductible. You can deduct those costs from your income, thus reducing your overall taxable income. In other words, the government is subsidizing your home.</p>

        <h2>Other Benefits</h2>
        <p>It’s easy to get carried away with all of the economic reasons for home ownership, but it’s important to remember that not every reason is financial. Have you ever wanted to paint the walls of your apartment? Well when you’re renting, you can’t. Has anything in your apartment ever needed updating, but the landlord refused to do it? When you own a home, you can make the space yours in almost any way you want. </p>
        <p>And you benefit when you do home improvements, both financially and psychologically. Homes generally have more space, for storage, living, etc. than other living arrangements. Not to mention that you have space outdoors for barbecuing, pets, and kids. Owning your home carries with it a sense of pride, accomplishment, and even an elevated social status.</p>
        <p>So when you’re considering buying a home, consider the broad range of benefits that owning a home can have. And always make sure you have an experienced real estate agent and loan officer to help make sure you’re getting a home that is right for you, both financially and psychologically.</p>

        <div className="previous_next d-flex">
                {previousBlog && (
                  <div className="previous_blog">
                  <Link to={previousBlog.link}>
                      <div className="top_blog">
                          <span><img src={Angle} className="img-fluid" alt="arrows" /></span>
                          Previous
                      </div>
                      <div className="bottom_blog">{previousBlog.title}</div>
                  </Link>
              </div>
              
                )}
                {nextBlog && (
                    <div className="previous_blog next_blog">
                        <Link to={nextBlog.link}>
                            <div className="top_blog">Next <span><img src={Angle} className="img-fluid" alt="arrows" /></span></div>
                            <div className="bottom_blog">{nextBlog.title}</div>
                        </Link>
                    </div>
                )}
            </div>

</div>


         </div>
         </div>
         </div>
       
        </div>
    );
}

export default SingleBlog;
