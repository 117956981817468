
import React, { useState, useEffect, useRef } from 'react';
import './PropertyFeaturesDropdown.css';
import Sliders from '../../../public/assets/s3.svg';
import Bedrooms from '../../../public/assets/amenties2.png';
import Shower from '../../../public/assets/amenties3.png';

function PropertyFeaturesDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null); 
    const [lotSizeUnit, setLotSizeUnit] = useState('acres'); 
    const handleDropdownClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, []);

    return (
        <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
            <div className="sel_icon"><img src={Sliders} alt="propertyType" /></div>
            <div className="sel_text">Property Features</div>
            
            {isOpen && (
                   <div className="property_drop features_list" onClick={handleDropdownClick}>
                   <div className="two_input_blocks d-flex">
                       <div className="left_input_blocks">
                           <div className="icon_feature"><img src={Bedrooms} alt="bedrooms" /></div>
                           <input type="text" className="form-control" placeholder="No. of Bedrooms" />
                       </div>
                       <div className="right_input_blocks">
                           <div className="icon_feature"><img src={Shower} alt="shower" /></div>
                           <input type="text" className="form-control" placeholder="No. of Bathrooms" />
                       </div>
                   </div>

                   <h3>Square Feet</h3>
                   <div className="two_input_blocks d-flex square_ft">
                       <div className="left_input_blocks">
                           <input type="text" className="form-control" placeholder="Min sqft" />
                       </div>
                       <div className="right_input_blocks">
                           <input type="text" className="form-control" placeholder="Max sqft" />
                       </div>
                   </div>

                   <div className="d-flex acre_swap">
                       <div className="left_swap"><h3>Lot Size</h3></div>
                       <div className="right_swap">
                           <div className="unique-radio-wrapper">
                               <input
                                   type="radio"
                                   id="uniqueRadio1"
                                   name="unique-radio-group"
                                   defaultChecked
                                   onChange={() => setLotSizeUnit('acres')}
                               />
                               <label htmlFor="uniqueRadio1" className="unique-radio-label"><span>acres</span></label>

                               <input
                                   type="radio"
                                   id="uniqueRadio2"
                                   name="unique-radio-group"
                                   onChange={() => setLotSizeUnit('sqft')}
                               />
                               <label htmlFor="uniqueRadio2" className="unique-radio-label"><span>sqft</span></label>
                           </div>
                       </div>
                   </div>

                   <div className="two_input_blocks d-flex square_ft">
                       <div className="left_input_blocks">
                           <input
                               type="text"
                               className="form-control"
                               placeholder={`Min ${lotSizeUnit}`}
                           />
                       </div>
                       <div className="right_input_blocks">
                           <input
                               type="text"
                               className="form-control"
                               placeholder={`Max ${lotSizeUnit}`}
                           />
                       </div>
                   </div>
               </div>
            )}
        </div>
    );
}

export default PropertyFeaturesDropdown;


