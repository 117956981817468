import React, { useState, useEffect, useRef } from 'react';
import './LifeStyleSearchDropdown.css';
import SearchImage3 from '../../../public/assets/s2.svg';
import Search from '../../../public/assets/search.svg';
import crossing from '../../../public/multiply.svg';
import Star from '../../../public/star.svg';
import ReactSlider from 'react-slider';

function LifeStyleSearchDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, []);


/* sliders */
const [sliderValueCoffee, setSliderValueCoffee] = useState(10000); // Represents 10 miles
const [activeModeCoffee, setActiveModeCoffee] = useState('Distance');

const [sliderValueSchool, setSliderValueSchool] = useState(10000); // Represents 10 miles
const [activeModeSchool, setActiveModeSchool] = useState('Distance');

const [sliderValueGym, setSliderValueGym] = useState(10000); // Represents 10 miles
const [activeModeGym, setActiveModeGym] = useState('Distance');


const renderThumbCoffee = (props) => {
    let displayValue;
    if (activeModeCoffee === 'Distance') {
        displayValue = `${Math.round(sliderValueCoffee / 1000)} miles`; 
    } else {
        displayValue = `${Math.round(sliderValueCoffee / (200000/180))} mins`; 
    }
    return (<div {...props}>{displayValue}</div>);
};




    const renderThumbSchool = (props) => {
        let displayValue;
        if (activeModeSchool === 'Distance') {
            displayValue = `${Math.round(sliderValueSchool / 1000)} miles`; 
        } else {
            displayValue = `${Math.round(sliderValueSchool / (200000/180))} mins`; 
        }
        return (<div {...props}>{displayValue}</div>);
    };
    




    const renderThumbGym = (props) => {
        let displayValue;
        if (activeModeGym === 'Distance') {
            displayValue = `${Math.round(sliderValueGym / 1000)} miles`; 
        } else {
            displayValue = `${Math.round(sliderValueGym / (200000/180))} mins`; 
        }
        return (<div {...props}>{displayValue}</div>);
    };
    

   

    /* sliders */


// State to remember which icon is active in each row
const [activeIconRow1, setActiveIconRow1] = useState("Vector");
const [activeIconRow2, setActiveIconRow2] = useState("Vector");
const [activeIconRow3, setActiveIconRow3] = useState("Vector");


const handleIconClickRow1 = (iconName) => {
    setActiveIconRow1(iconName);
};

const handleIconClickRow2 = (iconName) => {
    setActiveIconRow2(iconName);
};

const handleIconClickRow3 = (iconName) => {
    setActiveIconRow3(iconName);
};

// State to remember which icon is active in each row

/* sidebar */
const [isOpenSearchOne, setIsOpenSearchOne] = useState(false);
const [isOpenSearchTwo, setIsOpenSearchTwo] = useState(false);
const [isOpenSearchThree, setIsOpenSearchThree] = useState(false);

const handleInputOne = () => {
    setIsOpenSearchOne(true);
    setIsOpenSearchTwo(false);
    setIsOpenSearchThree(false);
};

const handleInputTwo = () => {
    setIsOpenSearchOne(false);
    setIsOpenSearchTwo(true);
    setIsOpenSearchThree(false);
};

const handleInputThree = () => {
    setIsOpenSearchOne(false);
    setIsOpenSearchTwo(false);
    setIsOpenSearchThree(true);
};





const [suggestions, setSuggestions] = useState([]);

const dummySuggestions = [
    { name: "Anytime Fitness", address: "1234 Espresso Street, Seattle, WA 98101", status: "Open", phone: "(555) 123-4567", rating: "4.6", reviewCount: "148" },
    { name: "Java Gym", address: "5678 Latte Lane, Seattle, WA 98102", status: "Closed", phone: "(555) 234-5678", rating: "4.2", reviewCount: "95" },
    { name: "The Fitness Brew", address: "1357 Americano Avenue, Seattle, WA 98103", status: "Open", phone: "(555) 345-6789", rating: "4.8", reviewCount: "200" },
    { name: "GymBean", address: "2468 Mocha Boulevard, Seattle, WA 98104", status: "Open", phone: "(555) 456-7890", rating: "4.7", reviewCount: "180" },
    { name: "Workout Espresso", address: "9101 Frappuccino Street, Seattle, WA 98105", status: "Closed", phone: "(555) 567-8901", rating: "4.5", reviewCount: "120" },
    { name: "Anytime Fitness", address: "1234 Espresso Street, Seattle, WA 98101", status: "Open", phone: "(555) 123-4567", rating: "4.6", reviewCount: "148" },
    { name: "Java Gym", address: "5678 Latte Lane, Seattle, WA 98102", status: "Closed", phone: "(555) 234-5678", rating: "4.2", reviewCount: "95" },
    { name: "The Fitness Brew", address: "1357 Americano Avenue, Seattle, WA 98103", status: "Open", phone: "(555) 345-6789", rating: "4.8", reviewCount: "200" },
    { name: "GymBean", address: "2468 Mocha Boulevard, Seattle, WA 98104", status: "Open", phone: "(555) 456-7890", rating: "4.7", reviewCount: "180" },
    { name: "Workout Espresso", address: "9101 Frappuccino Street, Seattle, WA 98105", status: "Closed", phone: "(555) 567-8901", rating: "4.5", reviewCount: "120" }
];

    useEffect(() => {
      
        const fetchData = async () => {
            try {
                const response = await fetch('YOUR_API_ENDPOINT');
                const data = await response.json();
                setSuggestions(data); 
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [])


    /* search-icon */

    const [inputValue, setInputValue] = useState('');
    const [showCrossIcon, setShowCrossIcon] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        if (e.target.value !== '') {
            setShowCrossIcon(true);
        } else {
            setShowCrossIcon(false);
        }
    };

    const clearInput = () => {
        setInputValue('');
        setShowCrossIcon(false);
    };

    const handleInputFocus = () => {
        if (inputValue !== '') {
            setShowCrossIcon(true);
        }
    };

    const handleInputBlur = () => {
        if (inputValue === '') {
            setShowCrossIcon(false);
        }
    };



/* sidebar */


    return (


<div className="search_suggeations">   {/* new-class-added */}

{isOpenSearchOne && (
            <div className="suggestions_sidebar">
                <div className='single_search_area'>
            <input 
                type="text" 
                className="form-control" 
                placeholder="Coffee Shop Near Me" 
                value={inputValue} 
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
            />
            <div className="sear_icon" onClick={() => setIsOpenSearchTwo(false)}>
                {showCrossIcon && (
                    <div className="cross_icon" onClick={clearInput}>
                        <img src={crossing} alt="Clear" />
                    </div>
                )}
                <img src={Search} alt="propertyType" />
            </div>
        </div>
                {dummySuggestions.map((suggestion, index) => (
                    <div key={index} className="single_suggestion">
                        <h3>{suggestion.name}</h3>
                        <p>{suggestion.address}</p>
                        <div className="main_suggestion d-flex">
                            <div className="left_sugg d-flex">
                                <div className="open_close">{suggestion.status}</div>
                                <div className="phone_sugges">{suggestion.phone}</div>
                            </div>
                            <div className="right_sugg">
                                <img src={Star} />{suggestion.rating} <span className="span_count">({suggestion.reviewCount})</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )}




 {/* second-sidebar */}

 {isOpenSearchTwo && (
<div className="suggestions_sidebar">
<div className='single_search_area'>
            <input 
                type="text" 
                className="form-control" 
                placeholder="School Near Me" 
                value={inputValue} 
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
            />
            <div className="sear_icon" onClick={() => setIsOpenSearchTwo(false)}>
                {showCrossIcon && (
                    <div className="cross_icon" onClick={clearInput}>
                        <img src={crossing} alt="Clear" />
                    </div>
                )}
                <img src={Search} alt="propertyType" />
            </div>
        </div>

                    {dummySuggestions.map((suggestion, index) => (
                    <div key={index} className="single_suggestion">
                        <h3>{suggestion.name}</h3>
                        <p>{suggestion.address}</p>
                        <div className="main_suggestion d-flex">
                            <div className="left_sugg d-flex">
                                <div className="open_close">{suggestion.status}</div>
                                <div className="phone_sugges">{suggestion.phone}</div>
                            </div>
                            <div className="right_sugg">
                                <img src={Star} />{suggestion.rating} <span className="span_count">({suggestion.reviewCount})</span>
                            </div>
                        </div>
                    </div>
                ))}



   
</div>
 )}

  {/* second-sidebar */}

   {/* Three-sidebar */}

 {isOpenSearchThree && (
<div className="suggestions_sidebar">
<div className='single_search_area'>
            <input 
                type="text" 
                className="form-control" 
                placeholder="Gym Near Me" 
                value={inputValue} 
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
            />
            <div className="sear_icon" onClick={() => setIsOpenSearchTwo(false)}>
                {showCrossIcon && (
                    <div className="cross_icon" onClick={clearInput}>
                        <img src={crossing} alt="Clear" />
                    </div>
                )}
                <img src={Search} alt="propertyType" />
            </div>
        </div>

                    {dummySuggestions.map((suggestion, index) => (
                    <div key={index} className="single_suggestion">
                        <h3>{suggestion.name}</h3>
                        <p>{suggestion.address}</p>
                        <div className="main_suggestion d-flex">
                            <div className="left_sugg d-flex">
                                <div className="open_close">{suggestion.status}</div>
                                <div className="phone_sugges">{suggestion.phone}</div>
                            </div>
                            <div className="right_sugg">
                                <img src={Star} />{suggestion.rating} <span className="span_count">({suggestion.reviewCount})</span>
                            </div>
                        </div>
                    </div>
                ))}



   
</div>
 )}

  {/* Three-sidebar */}




        <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
        <div className="sel_icon"><img src={SearchImage3} alt="propertyType" /></div>
        <div className="sel_text">Lifestyle Search (Optional)</div>

        {isOpen && (
            <div className="property_drop lifestyledrop" onClick={e => e.stopPropagation()}>
                 <h3>LIFESTYLE CRITERIA</h3>
                <div className="single_full_search  d-flex">
                <div className='left_lifestyle '>

                  
                    
                    <div className='single_search_area '>
                        <input type='text' className="form-control" placeholder="Coffee Shop Near Me" onFocus={handleInputOne}/>
                        <div className="sear_icon" onClick={() => setIsOpen(false)}>
                            <img src={Search} alt="propertyType" />
                        </div>
                    </div>
                    </div>



                    <div className="right_lifestyle">
<div className="distancetime d-flex">
<div className="left_time">
    <span 
        className={`link ${activeModeCoffee === 'Distance' ? 'active' : ''}`} 
        onClick={() => setActiveModeCoffee('Distance')}>
        Distance
    </span>
    /
    <span 
        className={`link ${activeModeCoffee === 'Time' ? 'active' : ''}`} 
        onClick={() => setActiveModeCoffee('Time')}>
        Time
    </span>
</div>
    <div className="right_time d-flex">
    <div className="icons_row">
    <div 
        className={`list_icon ${activeIconRow1 === "Vector" ? 'active' : ''}`}
        onClick={() => handleIconClickRow1("Vector")}>
        <span className="icon-Vector"></span>
    </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow1 === "Vector-1" ? 'active' : ''}`}
            onClick={() => handleIconClickRow1("Vector-1")}>
            <span className="icon-Vector-1"></span>
        </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow1 === "Vector-2" ? 'active' : ''}`}
            onClick={() => handleIconClickRow1("Vector-2")}>
            <span className="icon-Vector-2"></span>
        </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow1 === "icon-train" ? 'active' : ''}`}
            onClick={() => handleIconClickRow1("icon-train")}>
            <span className="icon-train"></span>
        </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow1 === "icon-car" ? 'active' : ''}`}
            onClick={() => handleIconClickRow1("icon-car")}>
            <span className="icon-car"></span>
        </div>
</div>
    </div>
    <ReactSlider
    className="horizontal-slider miles_time"
    thumbClassName="thumb"
    trackClassName="track"
    min={0} 
    max={200000} 
    value={sliderValueCoffee}
    onChange={setSliderValueCoffee}
    renderThumb={renderThumbCoffee}
/>
    </div>
</div>



                </div>


                {/* ************* */}

                <div className="single_full_search  d-flex">
                <div className='left_lifestyle '>

                   
                    
                    <div className='single_search_area '>
                        <input type='text' className="form-control" placeholder="Schools Near Me" onFocus={handleInputTwo}></input>
                        <div className="sear_icon" onClick={() => setIsOpen(false)}>
                            <img src={Search} alt="propertyType" />
                        </div>
                    </div>
                    </div>



                    <div className="right_lifestyle">
<div className="distancetime d-flex">
<div className="left_time">
    <span 
        className={`link ${activeModeSchool === 'Distance' ? 'active' : ''}`} 
        onClick={() => setActiveModeSchool('Distance')}>
        Distance
    </span>
    /
    <span 
        className={`link ${activeModeSchool === 'Time' ? 'active' : ''}`} 
        onClick={() => setActiveModeSchool('Time')}>
        Time
    </span>
</div>
<div className="right_time d-flex">
    <div className="icons_row">
    <div 
        className={`list_icon ${activeIconRow2 === "Vector" ? 'active' : ''}`}
        onClick={() => handleIconClickRow2("Vector")}>
        <span className="icon-Vector"></span>
    </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow2 === "Vector-1" ? 'active' : ''}`}
            onClick={() => handleIconClickRow2("Vector-1")}>
            <span className="icon-Vector-1"></span>
        </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow2 === "Vector-2" ? 'active' : ''}`}
            onClick={() => handleIconClickRow2("Vector-2")}>
            <span className="icon-Vector-2"></span>
        </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow2 === "icon-train" ? 'active' : ''}`}
            onClick={() => handleIconClickRow2("icon-train")}>
            <span className="icon-train"></span>
        </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow2 === "icon-car" ? 'active' : ''}`}
            onClick={() => handleIconClickRow2("icon-car")}>
            <span className="icon-car"></span>
        </div>
</div>
    </div>
 

<ReactSlider
    className="horizontal-slider miles_time"
    thumbClassName="thumb"
    trackClassName="track"
    min={0}  
    max={200000} 
    value={sliderValueSchool}
    onChange={setSliderValueSchool}
    renderThumb={renderThumbSchool}
/>

    </div>
</div>

                </div>


{/* *************** */}


<div className="single_full_search  d-flex">
                <div className='left_lifestyle '>

                   
                    
                    <div className='single_search_area '>
                        <input type='text' className="form-control" placeholder="Gym Near Me" onFocus={handleInputThree}></input>
                        <div className="sear_icon" onClick={() => setIsOpen(false)}>
                            <img src={Search} alt="propertyType" />
                        </div>
                    </div>
                    </div>



                    <div className="right_lifestyle">
<div className="distancetime d-flex">
<div className="left_time">
    <span 
        className={`link ${activeModeGym === 'Distance' ? 'active' : ''}`} 
        onClick={() => setActiveModeGym('Distance')}>
        Distance
    </span>
    /
    <span 
        className={`link ${activeModeGym === 'Time' ? 'active' : ''}`} 
        onClick={() => setActiveModeGym('Time')}>
        Time
    </span>
</div>
<div className="right_time d-flex">
    <div className="icons_row">
    <div 
        className={`list_icon ${activeIconRow3 === "Vector" ? 'active' : ''}`}
        onClick={() => handleIconClickRow3("Vector")}>
        <span className="icon-Vector"></span>
    </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow3 === "Vector-1" ? 'active' : ''}`}
            onClick={() => handleIconClickRow3("Vector-1")}>
            <span className="icon-Vector-1"></span>
        </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow3 === "Vector-2" ? 'active' : ''}`}
            onClick={() => handleIconClickRow3("Vector-2")}>
            <span className="icon-Vector-2"></span>
        </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow3 === "icon-train" ? 'active' : ''}`}
            onClick={() => handleIconClickRow3("icon-train")}>
            <span className="icon-train"></span>
        </div>
</div>
<div className="icons_row">
<div 
            className={`list_icon ${activeIconRow3 === "icon-car" ? 'active' : ''}`}
            onClick={() => handleIconClickRow3("icon-car")}>
            <span className="icon-car"></span>
        </div>
</div>
    </div>
    <ReactSlider
    className="horizontal-slider miles_time"
    thumbClassName="thumb"
    trackClassName="track"
    min={0}  
    max={200000} 
    value={sliderValueGym}
    onChange={setSliderValueGym}
    renderThumb={renderThumbGym}
/>
    </div>

</div>

                </div>



             
            </div>
        )}
    </div></div>
);
}

export default LifeStyleSearchDropdown;
