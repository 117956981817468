import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Blogs/Blogs.css';
import Arrow from '../../public/assets/arrows.svg';
import BlogPost from '../../public/assets/blogs/blog.png'; 

function AllBlogs() {
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 12;

    // Mock blog data
    const mockBlogs = Array.from({ length: 125 }, (_, index) => ({
        id: index,
        title: `Blog Title ${index + 1}`,
        summary: 'As a dedicated and knowledgeable real estate agent I can provide you with the highly specialized information that you will need to make the right decision. Its the combination....',
        imageUrl: BlogPost, 
    }));

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = mockBlogs.slice(indexOfFirstBlog, indexOfLastBlog);
    const totalPages = Math.ceil(mockBlogs.length / blogsPerPage);


    return (
        <div className="all_agents blogs_all">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Blogs</h1>
                        <div className="d-flex search_count blogs">

                            <div className='Second_coint'>
                            <div className="pagination">
                        <button className={`left_awo ${currentPage <= 1 ? 'disabled-btn' : ''}`} disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)}>
    <img src={Arrow} alt="Left Arrow" />
</button>

    
    {[...Array(totalPages)].map((_, i) => {
        if(i === 0 || i === totalPages - 1 || (i < 5 && currentPage < 6) || (i < currentPage + 2 && i > currentPage - 2) || (i > totalPages - 5 && currentPage > totalPages - 5)) {
            return (
                <button 
                    key={i} 
                    onClick={() => setCurrentPage(i + 1)}
                    className={currentPage === i + 1 ? 'active' : ''}
                >
                    {i + 1}
                </button>
            )
        } else if(i === 5 && currentPage < 6) {
            return <span key={i}>...</span>
        } else if(i === currentPage + 2) {
            return <span key={i}>...</span>
        } else if(i === totalPages - 5 && currentPage > totalPages - 5) {
            return <span key={i}>...</span>
        }
        return null;
    })}
    
   
    <button className={`${currentPage >= totalPages ? 'disabled-btn' : ''}`} disabled={currentPage >= totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
    <img src={Arrow} alt="Right Arrow" /> 
</button>
</div>

                            </div>
                          
                            <div className="agents_count">
                <span className="ml">
                    {indexOfFirstBlog + 1}-{Math.min(indexOfLastBlog, mockBlogs.length)}
                </span> 
                of <span>{mockBlogs.length}</span> Results
            </div>
                        </div>



                        <div className="blog_posts d-flex">
                {currentBlogs.map(blog => (
                     <Link to={`/single-blog`} key={blog.id} className="single_blog_block">
                     <img src={blog.imageUrl} className="img-fluid" alt="Blog" />
                     <h2>{blog.title}</h2>
                     <p>{blog.summary}</p>
                 </Link>
                ))}
            </div>
                     
                        <div className="pagination">
                        <button className={`left_awo ${currentPage <= 1 ? 'disabled-btn' : ''}`} disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)}>
    <img src={Arrow} alt="Left Arrow" />
</button>

    
    {[...Array(totalPages)].map((_, i) => {
        if(i === 0 || i === totalPages - 1 || (i < 5 && currentPage < 6) || (i < currentPage + 2 && i > currentPage - 2) || (i > totalPages - 5 && currentPage > totalPages - 5)) {
            return (
                <button 
                    key={i} 
                    onClick={() => setCurrentPage(i + 1)}
                    className={currentPage === i + 1 ? 'active' : ''}
                >
                    {i + 1}
                </button>
            )
        } else if(i === 5 && currentPage < 6) {
            return <span key={i}>...</span>
        } else if(i === currentPage + 2) {
            return <span key={i}>...</span>
        } else if(i === totalPages - 5 && currentPage > totalPages - 5) {
            return <span key={i}>...</span>
        }
        return null;
    })}
    
   
    <button className={`${currentPage >= totalPages ? 'disabled-btn' : ''}`} disabled={currentPage >= totalPages} onClick={() => setCurrentPage(currentPage + 1)}>
    <img src={Arrow} alt="Right Arrow" /> 
</button>
</div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllBlogs;
