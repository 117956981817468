// Testimonial.js
import React from 'react';
import './Testimonial.css';
import t1Image from '../../../public/assets/t1.jpeg';
import t2Image from '../../../public/assets/t2.jpeg';
import t3Image from '../../../public/assets/t3.jpeg';
import t4Image from '../../../public/assets/t4.jpeg';
import t5Image from '../../../public/assets/t5.jpeg';
import t6Image from '../../../public/assets/t6.jpeg';

function SingleTestimonial({ imageUrl, name, designation, text, rating }) {
    return (
        <div className="testimonial">
            <div className="testimonial-header">
                <div className="image-container">
                <img src={imageUrl} alt={name} />

                </div>
                <div className="info">
                    <div className="name">{name}</div>
                    <div className="designation">{designation}</div>
                </div>
            </div>
            <div className="testimonial-text">
                {text}
            </div>
            <div className="rating">
                {[...Array(rating)].map((_, i) => <i key={i} className="fa fa-star"></i>)}
            </div>
        </div>
    );
}





function Testimonial({ limit }) {

        const testimonialsData = [ 
        { 
            imageUrl: t1Image,
         name: "Dana Gilmore",
         designation: "developer", 
         text: "I reviewed and purchased a number of different WordPress Themes before settling on Wp Residence", 
         rating: 5 
        },

        {
             imageUrl: t2Image, 
        name: "Susan Barkley", 
        designation: "happy seller", 
        text: "The WP Estate team did an outstanding job helping me buy and create my first real estate website.", 
        rating: 5 
    },
        {
             imageUrl: t3Image, 
             name: "Lisa Simpson", 
             designation: "happy buyer", 
             text: "We hired the WP Estate team as our buyer agent because they are the perfect team for real estate projects.", 
             rating: 5 
            },
        {
             imageUrl: t4Image, 
             name: "Sara Loreley", 
             designation: "buyer", 
             text: "I reviewed and purchased a number of different WordPress Themes before settling on Wp Residence.", 
             rating: 5 
            },
        { 
            imageUrl: t5Image, 
            name: "Virginia Wolf", 
            designation: "happy seller", 
            text: "The WP Estate team did an outstanding job helping me buy and create my first real estate website.", 
            rating: 5 
        },

        { 
            imageUrl: t6Image, 
            name: "Jessica Fowley", 
            designation: "happy buyer", 
            text: "We hired the WP Estate team as our buyer agent because they are the perfect team for real estate projects.", 
            rating: 5 
        },
       
    ];

    const displayLimit = limit || testimonialsData.length;

    return (
        <div className="testimonials">
            <div className="container">
                <div className="row">
                    <div className="testimonials_heading">
                        <h2>Testimonials</h2>
                        <p>Publish the best of your client testimonials and let the world know what a great agent or real estate agency you are. Testimonials build trust.</p>
                    </div>
                </div>

                <div className="row">
                {testimonialsData.slice(0, displayLimit).map((testimonial, index) => (
                    <div className="col-lg-4" key={index}>
                        <SingleTestimonial {...testimonial} />
                    </div>
                ))}
            </div>
            </div>
        </div>
    );
}

export default Testimonial;
