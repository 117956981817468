import React from 'react';
import '../Forecloures/Forecloures.css'

import SearchForm from '../Forecloures/SearchForm/SearchForm';
import Map from '../Forecloures/Map/Map';
import PropertySearchResult from '../Forecloures/PropertySearchResult/PropertySearchResult';










function Forecloures() {
    return (
        <div className="searchview-container">
            <SearchForm />
            <Map />
            <PropertySearchResult />
           
          
        </div>
    );
}

export default Forecloures;







