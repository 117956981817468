import React from 'react';
import './Footer.css';
import c1Image from '../../public/assets/c1.png'; 
import c2Image from '../../public/assets/c2.png'; 
import c3Image from '../../public/assets/c3.png'; 

function Footer() {
    return (
        <div className="custom-footer">
              <div className="container">
            <div className="row">
                <div className="custom-footer-col col-lg-4 col-md-6 col-sm-12">
                    <h3 className="footer-heading">Contact Us</h3>
                    <p className="footer-address"><i class="fa fa-building" aria-hidden="true"></i> 20001 SW Tualatin Valley Hwy Beaverton, OR 97003</p>
                    <p className="footer-address"><i class="fa fa-phone" aria-hidden="true"></i> 503.259.2100</p>
                    <p className="footer-address"><i class="fa fa-print" aria-hidden="true"></i>503.259.2100</p>
                    <p className="footer-address"><i class="fa fa-envelope-o" aria-hidden="true"></i> allprofessionalsre@gmail.com </p>
                    <p className="footer-address"><i class="fa fa-skype" aria-hidden="true"></i> allprofessionalsre</p>
                    <p className="footer-address"><i class="fa fa-desktop" aria-hidden="true"></i> allprofessionalsre</p>
                    {/* Add other links with icons here */}
                </div>
                <div className="custom-footer-col col-lg-4 col-md-6 col-sm-12">
                    <h3 className="footer-heading">Featured Communities</h3>
                    <p className="footer-link"><a href="#"><i className="fa fa-city"></i> Aloha, OR</a></p>
                    <p className="footer-link"><a href="#"><i className="fa fa-city"></i> Banks, OR</a></p>
                    <p className="footer-link"><a href="#"><i className="fa fa-city"></i> Beaverton, OR </a></p>
                    <p className="footer-link"><a href="#"><i className="fa fa-city"></i> Cornelius, OR</a></p>
                    <p className="footer-link"><a href="#"><i className="fa fa-city"></i> Hillsboro, OR</a></p>
                    <p className="footer-link"><a href="#"><i className="fa fa-city"></i> Newberg, OR</a></p>
                    <p className="footer-link"><a href="#"><i className="fa fa-city"></i> North Plains, OR</a></p>
                    <p className="footer-link"><a href="#"><i className="fa fa-city"></i> Portland, OR</a></p>
                </div>
                <div className="custom-footer-col col-lg-4 col-md-6 col-sm-12">
                    <div className="footer-social-icons">
                        <div className="social_all">
                        <i className="fa fa-facebook"></i>
                        <i className="fa fa-rss"></i>
                        <i className="fa fa-twitter"></i>
                        <i className="fa fa-dribbble"></i>
                        <i className="fa fa-google"></i>
                        </div>
                        <div className="social_all">
                        <i className="fa fa-linkedin"></i>
                        <i className="fa fa-tumblr"></i>
                        <i className="fa fa-pinterest-p"></i>
                        <i className="fa fa-youtube-play"></i>
                        <i className="fa fa-vimeo"></i>
                    </div>
                    </div>
                    <div className="newsletter">
                    <h3>Homes by Email</h3>
                    <p>Be the first to see new listings as soon as they hit the market!</p>
                    <div className="footer-newsletter">
                        <input type="text" placeholder="Enter your email Address" />
                        <button>Join</button></div>
                    </div>
                    </div>
               
            </div>

<div className="d-flex foot_clients">
 
    <div className="client-box">
    <img src={c1Image} className="img-fluid" alt="cta" />

    </div>
 
    <div className="client-box">
    <img src={c2Image} className="img-fluid" alt="cta" />

    </div>
   
    <div className="client-box">
    <img src={c3Image} className="img-fluid" alt="cta" />

    </div>
   
    <div className="client-box">
    <img src={c1Image} className="img-fluid" alt="cta" />

    </div>
    
    <div className="client-box">
    <img src={c3Image} className="img-fluid" alt="cta" />

    </div>
 
</div>

<div className="footer_text">©2023, Lincoln County MLS. All rights reserved. Information deemed to be reliable but not guaranteed. The data relating to real estate for sale on this website comes from Lincoln County MLS and the Broker Reciprocity Program.sm. Real estate listings held by brokerage firms other than All Professionals Real Estate are marked with the BR logo and detailed information about them includes the name of the listing brokers. Listing broker has attempted to offer accurate data, but buyers are advised to confirm all items.Information last updated on 2023-09-12 17:30:13.</div>

<div className="footer-copyright">Copyright WOVNN. All Rights Reserved.</div>
<div className="footer_links d-flex">
<div className="links"><a href="">Documentation</a></div>
<div className="links"><a href="">Video Tutorial</a></div>
<div className="links"><a href="">Client Support</a></div>

</div>


        </div> 
        </div> );} 
        export default Footer;
