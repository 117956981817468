import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import './DreamHomeFinder.css';
import PropertyTypeDropdown from '../../ReusableComponents/PropertyTypeDropdown/PropertyTypeDropdown';
import BudgetDropdown from '../../ReusableComponents/BudgetDropdown/BudgetDropdown';
import Preferences from '../../ReusableComponents/Preferences/Preferences';
import Modal2 from '../../../public/assets/modal2.svg';
import Cross from '../../../public/assets/cross.svg'




function DreamHomeFinder({ showModal, setShowModal }) {

        /* form-handle */

    const [formData, setFormData] = useState({
        inquiryType: 'select',
        information: '',
        firstName: '',
        lastName: '',
        email: '',
        location: 'location1',
        zipcode: '',
        propertyType: 'type1',
        budget: 'budget1',
        gdprAgreement: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        
        console.log(formData);
        alert('Data ready to be sent: ' + JSON.stringify(formData));


        
    };

    /* form-handle */

/* property-dropdown-data */
const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
const [propertyFeaturesOpen, setPropertyFeaturesOpen] = useState(false);
const [budgetOpen, setBudgetOpen] = useState(false);
const [lifestyleOpen, setLifestyleOpen] = useState(false);

const closeAllDropdowns = () => {
    setPropertyTypeOpen(false);
    setPropertyFeaturesOpen(false);
    setBudgetOpen(false);
    setLifestyleOpen(false);
};

const handleDocumentClick = (e) => {
    if (
        !e.target.closest('.select_custom') &&
        (propertyTypeOpen || propertyFeaturesOpen || budgetOpen || lifestyleOpen)  
    ) {
        closeAllDropdowns();
    }
};

/* for dropdowns to show inside overflow hidden form */

useEffect(() => { 
    document.addEventListener('click', handleDocumentClick);
    
   
}, ); 



    return (
        <div className="free_market_analysis mutual_popuus">
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div class="modal_closer"><img src={Cross} alt="close Icon" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)} />
</div>
                        <div className="modal-body">
                          
                          <div className="overall_mutual_modal">
                            <div className="left_mutual_modal dream_only">
                            <div className="inquery property-detail-form">
         
            <form className="inq_form" onSubmit={handleSubmit}>
                
            
                
                <div className="mb-3">
                    <label htmlFor="information" className="form-label">Information</label>
                   
                </div>
                
                <div className="row mb-3">
                    <div className="col">
                        <input 
                            type="text" 
                            className="form-control"
                            name="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col">
                        <input 
                            type="text" 
                            className="form-control"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                </div>



                <div className="row mb-3">
                    <div className="col">
                        <input 
                            type="text" 
                            className="form-control"
                            name="Phone Number"
                            placeholder="Phone No."
                            value={formData.phoneno}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col">
                        <input 
                            type="text" 
                            className="form-control"
                            name="fax"
                            placeholder="Fax"
                            value={formData.fax}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                
                <div className="mb-3">
                    <input 
                        type="email" 
                        className="form-control" 
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">Location</label>
                            <select 
                                id="location" 
                                className="form-select"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                            >
                                <option value="location1">Location 1</option>
                                <option value="location2">Location 2</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3 marg_top">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="zipcode"
                                name="zipcode"
                                placeholder="Zip Code"
                                value={formData.zipcode}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="schools"
                                name="school"
                                placeholder="school(s) desired"
                                value={formData.schools}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                    <Preferences/>
                    </div>
                 



                </div>






                
                
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="mb-3 ">
                            <label htmlFor="propertyType" className="form-label">Property</label>
                            <PropertyTypeDropdown
                                        isOpen={propertyTypeOpen}
                                        toggleDropdown={() => {
                                            setPropertyTypeOpen(!propertyTypeOpen);
                                            closeAllDropdowns();
                                        }}
                                    />     
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className="mb-3 marg_top">
                        <BudgetDropdown
                                        isOpen={budgetOpen}
                                        toggleDropdown={() => {
                                            setBudgetOpen(!budgetOpen);
                                            closeAllDropdowns();
                                        }}
                                    />
                        </div>
                    </div>
                    <div className="col-md-12 ">

                    <div className="mb-3">
                          
                            <select 
                                id="location" 
                                className="form-select"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                            >
                                <option value="location1">How soon are you looking to purchase?</option>
                                <option value="location2">Location 2</option>
                            </select>
                        </div>

                    </div>




                    <div className="question col-md-12 ">
    <p>Will you need to sell your present home?</p>
    <label className="custom-radio">
        
        <input type="radio" name="sellHome" value="yes" />
        <span className="checkmark"></span>
        Yes
    </label>
    <label className="custom-radio">
       
        <input type="radio" name="sellHome" value="no" />
        <span className="checkmark"></span>
        No
    </label>
</div>


<div className="question col-md-12">
    <p>Are you pre-qualified for a loan?</p>
    <label className="custom-radio">
      
        <input type="radio" name="loan" value="yes" />
        <span className="checkmark"></span>
        Yes
    </label>
    <label className="custom-radio">
       
        <input type="radio" name="loan" value="no" />
        <span className="checkmark"></span>
        No
    </label>
</div>

<div className="question col-md-12">
    <p>Would you like more information about financing?</p>
    <label className="custom-radio">
      
        <input type="radio" name="financing" value="yes" />
        <span className="checkmark"></span>
        Yes
    </label>
    <label className="custom-radio">
      
        <input type="radio" name="financing" value="no" />
        <span className="checkmark"></span>
        No
    </label>
</div>

<div className="col-md-12">

<div class="bootstrap_textarea">
  <textarea class="form-control" placeholder="Other Preferences (Please Specify...)"></textarea>
</div>


</div>


<div className="col-md-12">

<div class="switch">
  <p>Turn on if you would like me to mail you a personalized list of homes or properties that meet your criteria:</p>
</div>
<label class="toggle-switch">
  <input type="checkbox" class="toggle-input"/>
  <span class="toggle-slider"></span>
</label>



</div>








                </div>
                
              
                
                <div className="d-flex ">
                    <button type="submit" className="custom_btn">Submit</button>
                </div>
                
            </form>
        </div>

                            </div>
                            <div className="right_mutual_modal">

<h1>Dream Home Finder</h1>
<p>For detailed information on buying a home or property, please complete the form below describing the type of home or property you are looking for. Someone will get back to you as quickly as possible with your results!</p>
<img src={Modal2} alt="Modal-Image" className="img-fluid" />
                                
                            </div>
                          </div>

                        </div>
                       
                    </div>
                </div>
            </div>
            {/* Backdrop for modal */}
            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
    );
}

export default DreamHomeFinder;

  {/*   <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button> */}
