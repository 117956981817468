import React from 'react';
import './WhyWovnn.css';
import PropertySidebarForm from '../../PropertySidebarForm/PropertySidebarForm';


function WhyWovnn() {
    return <div className="why-wovnn">
        <div className="container-fluid">
       
        <div className="row">
        <div className="col-lg-8 tpdn">

<div className="overall_why d-flex">
  <div className="left_why">
    <h2>Why Wovnn Is The Perfect Choice?</h2>
  </div>
  <div className="right_why top_dn left_why">
<h3>02.</h3>
<h4>Expertise and Experience</h4>
<p>Keep track of your leads without having to pay for an external CRM</p>

  </div>
</div>
<div className="overall_why d-flex mmt">
  <div className="right_why left_why mt-30">
  <h3>01.</h3>
<h4>Market Insights and Technology</h4>
<p>Never miss a sale! It's never been easier to 
turn leads into real customers</p>  </div>
  <div className="right_why left_why top_doen">
<h3>03.</h3>
<h4>Exceptional Client Service</h4>
<p>Customize your website according to your
expectations and requirements</p>

  </div>
</div>



        </div>
        <div className="col-lg-4">


        <PropertySidebarForm /> 

            
        </div>
        </div>

     </div>



     </div>;
}

export default WhyWovnn;
