import React from 'react';
import './CtaOne.css';


function CtaOne() {
    return <div className="cta_one">
        <div className="container-fluidic">
       
        <div className="cta_blocks d-flex">
        <div className="cta_block_one d-flex">
        <div className="cta_image">  <img src="/assets/house.png" className="img-fluid" alt="cta" /></div>
        <div className="cta_text">
            <h3>What's Your Home Worth?</h3>
            <p>Let us quickly and accurately assess your home's current value.</p>
        </div>

        </div>

        <div className="cta_block_one two_block d-flex">
        <div className="cta_image">  <img src="/assets/location-pin.png" className="img-fluid" alt="cta" /></div>
        <div className="cta_text">
            <h3>Search by Area</h3>
            <p>Easy home search broken down by area and price.</p>
        </div>

        </div>


        <div className="cta_block_one three_block d-flex">
        <div className="cta_image">  <img src="/assets/call.png" className="img-fluid" alt="cta" /></div>
        <div className="cta_text">
            <h3>Contact Us</h3>
            <p>Have a question? Let's get in touch.</p>
        </div>

        </div>
        </div>

     </div>
     </div>;
}

export default CtaOne;
