import React, { useState, useEffect, useRef } from 'react';
import './AdvancedSearch.css';
import Cross from '../../../public/assets/cross.svg'
import Chevron from '../../../public/chvdn.svg';
import PropertyTypeDropdown from '../../ReusableComponents/PropertyTypeDropdown/PropertyTypeDropdown';
import BudgetDropdown from '../../ReusableComponents/BudgetDropdown/BudgetDropdown';




function AdvancedSearch({ showModal, setShowModal }) {


/* property-dropdown-data */
const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);

const [budgetOpen, setBudgetOpen] = useState(false);
const [lifestyleOpen, setLifestyleOpen] = useState(false);
const [bedsOpen, setBedsOpen] = useState(false);


const [bathroomsOpen, setBathroomsOpen] = useState(false);
const [garageOpen, setGarageOpen] = useState(false);
const [storiesOpen, setStoriesOpen] = useState(false);
const [parkingSpaceOpen, setParkingSpaceOpen] = useState(false);
const [unitsOpen, setUnitsOpen] = useState(false);
const [timeOnSiteOpen, setTimeOnSiteOpen] = useState(false);
const [dataSourceOpen, setDataSourceOpen] = useState(false);

const closeAllDropdowns = () => {
    setPropertyTypeOpen(false);
    setBedsOpen(false);
    setBudgetOpen(false);
    setLifestyleOpen(false);

    setBathroomsOpen(false);
    setGarageOpen(false);
    setStoriesOpen(false);
    setParkingSpaceOpen(false);
    setUnitsOpen(false);
    setTimeOnSiteOpen(false);
    setDataSourceOpen(false);
};

const handleDocumentClick = (e) => {
    if (
        !e.target.closest('.select_custom') &&
        (propertyTypeOpen || bedsOpen || budgetOpen || lifestyleOpen || bathroomsOpen || garageOpen || storiesOpen || parkingSpaceOpen || unitsOpen || timeOnSiteOpen || dataSourceOpen )  
    ) {
        closeAllDropdowns();
    }
};


document.addEventListener('click', handleDocumentClick);

const dropdownRef = useRef(null); 
const [isOpen, setIsOpen] = useState(false);

const handleDropdownClick = (e) => {
    e.stopPropagation();
};


/* dropdown-selection */

const [isDropdownOpen, setDropdownOpen] = useState(false);
const [currentSelection, setCurrentSelection] = useState('Any');
const dropdownElementRef = useRef(null);

const onOptionSelect = (optionValue) => {
    setCurrentSelection(optionValue);
    setDropdownOpen(false); 
};

useEffect(() => {
    // Function to handle document click
    const handleDocumentClick = (e) => {
        if (!e.target.closest('.select_custom')) {
            closeAllDropdowns();
        }
    };

   
    document.addEventListener('mousedown', handleDocumentClick);

   
    return () => {
        document.removeEventListener('mousedown', handleDocumentClick);
    };
}, []);

const [selectedBeds, setSelectedBeds] = useState('Beds');
const [selectedBathrooms, setSelectedBathrooms] = useState('Baths');
const [selectedGarage, setSelectedGarage] = useState('Garage');
const [selectedStories, setSelectedStories] = useState('Stories');
const [selectedParkingSpace, setSelectedParkingSpace] = useState('Parking Space');
const [selectedUnits, setSelectedUnits] = useState('Units');
const [selectedTimeOnSite, setSelectedTimeOnSite] = useState('Time On Site');
const [selectedDataSource, setSelectedDataSource] = useState('Data Source');

const handleBedsSelection = (option) => {
    setSelectedBeds(option);
    setBedsOpen(false); 
};

/* bathrooms */

const handleBathroomSelection = (option) => {
    setSelectedBathrooms(option);
    setBathroomsOpen(false); 
};


/* Garage */

const handleGarageSelection = (option) => {
    setSelectedGarage(option);
    setGarageOpen(false); 
};

/* Stories */

const handleStoriesSelection = (option) => {
    setSelectedStories(option);
    setStoriesOpen(false); 
};

/* Parking-Space */

const handleParkingSpaceSelection = (option) => {
    setSelectedParkingSpace(option);
    setParkingSpaceOpen(false); 
};

/* Parking-Space */

const handleUnitsSelection = (option) => {
    setSelectedUnits(option);
    setUnitsOpen(false); 
};


/* time-on-site */

const handleTimeOnSiteSelection = (option) => {
    setSelectedTimeOnSite(option);
    setTimeOnSiteOpen(false); 
};


/* data-source */

const handleDataSourceSelection = (option) => {
    setSelectedDataSource(option);
    setDataSourceOpen(false); 
};



/* lot-size */

const [lotSizeUnit, setLotSizeUnit] = useState('acres'); 

useEffect(() => {
    const handleOutsideClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
        document.removeEventListener('click', handleOutsideClick);
    }
}, []);

/* \\slide-toggle-content */
const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState(false);
const toggleAdvancedFilter = () => {
    setIsAdvancedFilterOpen(!isAdvancedFilterOpen);
};

/* search-btn */





    return (
        <div className="free_market_analysis mutual_popuus advanced_search_modal">
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div class="modal_closer"><img src={Cross} alt="close Icon" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)} />
</div>
                        <div className="modal-body">
                          
                          <div className="advanced_searchs">
                            <h2>Advanced Search</h2>

                            <form className="inq_form">
                           <div className="full_form">


                           <div className="flex_form_row">
                    <div className="flex_half_row">
                        <input 
                            type="text" 
                            className="form-control"
                            name="Enter City or Zip Code"
                            placeholder="Enter City or Zip Code"
                           
                        />
                    </div>
                    <div className="flex_half_row">
                         
                            <select 
                                id="location" 
                                className="form-select"
                                name="location"
                               
                            >
                                <option value="location1">Listing Type</option>
                                <option value="location2">Location 2</option>
                            </select>
                        </div>
                </div>


                <div className="row adv_sch_only">
                    <div className="col-md-6">
                        <div className="mb-3 ">
                            <label htmlFor="propertyType" className="form-label">Property</label>
                            <PropertyTypeDropdown
                                        isOpen={propertyTypeOpen}
                                        toggleDropdown={() => {
                                            setPropertyTypeOpen(!propertyTypeOpen);
                                            closeAllDropdowns();
                                        }}
                                    />     
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className="mb-3 marg_top">
                        <BudgetDropdown
                                        isOpen={budgetOpen}
                                        toggleDropdown={() => {
                                            setBudgetOpen(!budgetOpen);
                                            closeAllDropdowns();
                                        }}
                                    />
                        </div>
                    </div>
                    <div className="col-md-4">
    <div className="select_custom" onClick={() => setBedsOpen(!bedsOpen)}>
        <div className="sel_text">{selectedBeds}</div>

        {bedsOpen && (
            <div className="property_drop beds_list">
                {['Any', '1', '2', '3', '4'].map(option => (
                    <div 
                        key={option} 
                        className="custom_drop_list" 
                        onClick={() => handleBedsSelection(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        )}
    </div>
</div>

<div className="col-md-4">
    <div className="select_custom" onClick={() => setBathroomsOpen(!bathroomsOpen)}>
        <div className="sel_text">{selectedBathrooms}</div>

        {bathroomsOpen && (
            <div className="property_drop beds_list">
                {['Any', '1', '2', '3', '4', '5'].map(option => (
                    <div 
                        key={option} 
                        className="custom_drop_list" 
                        onClick={() => handleBathroomSelection(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        )}
    </div>
</div>
<div className="col-md-4">
    <div className="select_custom" onClick={() => setGarageOpen(!garageOpen)}>
        <div className="sel_text">{selectedGarage}</div>

        {garageOpen && (
            <div className="property_drop beds_list">
                {['Any', '1+car', '2+car', '3+car', '4+car'].map(option => (
                    <div 
                        key={option} 
                        className="custom_drop_list" 
                        onClick={() => handleGarageSelection(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        )}
    </div>
</div>
<div className="col-md-4">
    <div className="select_custom" onClick={() => setStoriesOpen(!storiesOpen)}>
        <div className="sel_text">{selectedStories}</div>

        {storiesOpen && (
            <div className="property_drop beds_list">
                {['Any', 'Single-Story', 'Multi-Story'].map(option => (
                    <div 
                        key={option} 
                        className="custom_drop_list" 
                        onClick={() => handleStoriesSelection(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        )}
    </div>
</div>
<div className="col-md-4">
    <div className="select_custom" onClick={() => setParkingSpaceOpen(!parkingSpaceOpen)}>
        <div className="sel_text">{selectedParkingSpace}</div>

        {parkingSpaceOpen && (
            <div className="property_drop beds_list">
                {['Any', '1', '2' , '3' , '4' , '5'].map(option => (
                    <div 
                        key={option} 
                        className="custom_drop_list" 
                        onClick={() => handleParkingSpaceSelection(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        )}
    </div>
</div>
<div className="col-md-4">
    <div className="select_custom" onClick={() => setUnitsOpen(!unitsOpen)}>
        <div className="sel_text">{selectedUnits}</div>

        {unitsOpen && (
            <div className="property_drop beds_list">
                {['Any', '1', '2' , '3' , '4' , '5'].map(option => (
                    <div 
                        key={option} 
                        className="custom_drop_list" 
                        onClick={() => handleUnitsSelection(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        )}
    </div>
</div>
<div className="col-md-4">
    <div className="select_custom" onClick={() => setTimeOnSiteOpen(!timeOnSiteOpen)}>
        <div className="sel_text">{selectedTimeOnSite}</div>

        {timeOnSiteOpen && (
            <div className="property_drop beds_list">
                {['Any', 'Less than 1 day', 'Less than 3 days' , 'Less than 1 week' , 'Less than 2 weeks' , 'Less than 1 Month', 'More than 1 Week', 'More than 1 Month', 'More than 3 Months', 'More than 6 Months'].map(option => (
                    <div 
                        key={option} 
                        className="custom_drop_list" 
                        onClick={() => handleTimeOnSiteSelection(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        )}
    </div>
</div>
<div className="col-md-5">
    <div className="select_custom" onClick={() => setDataSourceOpen(!dataSourceOpen)}>
        <div className="sel_text">{selectedDataSource}</div>

        {dataSourceOpen && (
            <div className="property_drop beds_list">
                {['Any', 'Lincoln County MLS', 'Regional MLS (PMAR, EMAR, CCAR)' , 'Southern Oregon MLS' , 'Tillamook Board of REALTORS®' , 'Willamette Valley MLS'].map(option => (
                    <div 
                        key={option} 
                        className="custom_drop_list" 
                        onClick={() => handleDataSourceSelection(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        )}
    </div>
</div>
                            <div className="col-md-3">

                            <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        Include Pending
    </label>

                            </div>



                            <div className="col-md-6">

<div className="all_dropd_dns">

    <div className="single_drop_check">
           <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
       Open House
    </label>
<div className="sub_options_house">
<div className="unique-radio-wrapper">
    <div className="single_rad_btn">
                               <input
                                   type="radio"
                                   id="uniqueRadio10"
                                   name="sel10"
                                   className="radious"
                                  
                               />
                               <label htmlFor="uniqueRadio10" className="unique-radio-label"><div className="peros">Any</div></label>
                               </div>
                               <div className="single_rad_btn">

                               <input
                                   type="radio"
                                   id="uniqueRadio11"
                                   name="sel2"
                                   className="radious"
                                 
                               />
                               <label htmlFor="uniqueRadio11" className="unique-radio-label"><div className="peros">Virtual</div></label>
                               </div>
                               <div className="single_rad_btn">
                               <input
                                   type="radio"
                                   id="uniqueRadio12"
                                   name="sel3"
                                   className="radious"
                                 
                               />
                               <label htmlFor="uniqueRadio12" className="unique-radio-label"><div className="peros">In-Person</div></label>

                               </div>
                           </div>
</div>

    </div>

        <div className="single_drop_check">
           <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
       Virtual Tour
    </label>
    </div>

        <div className="single_drop_check">
           <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
       Price Reduced
    </label>
    </div>

        <div className="single_drop_check">
           <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
       Foreclosure
    </label>
    </div>

        <div className="single_drop_check">
           <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
       Auction
    </label>
    </div>

        <div className="single_drop_check">
           <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
     New Construction
    </label>
    </div>

</div>
                                
                            </div>
                            <div className="col-md-6">

<div className="motial">
<h3>Square Feet</h3>
                   <div className="two_input_blocks d-flex square_ft">
                       <div className="left_input_blocks">
                           <input type="text" className="form-control" placeholder="Min sqft" />
                       </div>
                       <div className="right_input_blocks">
                           <input type="text" className="form-control" placeholder="Max sqft" />
                       </div>
                   </div>

                   <div className="d-flex acre_swap">
                       <div className="left_swap"><h3>Lot Size</h3></div>
                       <div className="right_swap">
                           <div className="unique-radio-wrapper">
                               <input
                                   type="radio"
                                   id="uniqueRadio1"
                                   name="unique-radio-group"
                                   defaultChecked
                                   onChange={() => setLotSizeUnit('acres')}
                               />
                               <label htmlFor="uniqueRadio1" className="unique-radio-label"><span>acres</span></label>

                               <input
                                   type="radio"
                                   id="uniqueRadio2"
                                   name="unique-radio-group"
                                   onChange={() => setLotSizeUnit('sqft')}
                               />
                               <label htmlFor="uniqueRadio2" className="unique-radio-label"><span>sqft</span></label>
                           </div>
                       </div>
                   </div>

                   <div className="two_input_blocks d-flex square_ft">
                       <div className="left_input_blocks">
                           <input
                               type="text"
                               className="form-control"
                               placeholder={`Min ${lotSizeUnit}`}
                           />
                       </div>
                       <div className="right_input_blocks">
                           <input
                               type="text"
                               className="form-control"
                               placeholder={`Max ${lotSizeUnit}`}
                           />
                       </div>
                   </div>

                   <div className="year_built">
                   <h3>Year Built</h3>
                   <div className="flex_form_row">
                    <div className="flex_half_row">
                        <input 
                            type="text" 
                            className="form-control"
                            name="Enter City or Zip Code"
                            placeholder="Min year"
                           
                        />
                    </div>
                    <div className="flex_half_row">
                    <input 
                            type="text" 
                            className="form-control"
                            name="Enter City or Zip Code"
                            placeholder="Max year"
                           
                        />
                          
                        </div>
                </div>
                   </div>
</div>



                            </div>


                            <div className="col-md-6 topper">
                            <input 
                            type="text" 
                            className="form-control"
                            name="keyword"
                            placeholder="Type / choose keywords from the list"
                           
                        />

                            </div>
                            <div className="col-md-6">
                            <div className="hoa_fees">
                   <h3>HOA Monthly Fee</h3>
                   <div className="flex_form_row">
                    <div className="flex_half_row">
                    <select 
                                id="location" 
                                className="form-select"
                                name="location"
                               
                            >
                                <option value="location1">Min fee</option>
                                <option value="location2">Location 2</option>
                            </select>
                    </div>
                    <div className="flex_half_row">
                    <select 
                                id="location" 
                                className="form-select"
                                name="location"
                               
                            >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                            </select>
                          
                        </div>
                </div>
                   </div>
                

</div>
<div className="hidden_submit">
    {!isAdvancedFilterOpen && (
        <button type="submit" className="custom_btn search_forming hidebtn">Search</button>
    )}
</div>


<div className="chavron">
<div className='comercial_block d-flex' onClick={toggleAdvancedFilter}>
            <div className="left_commerc">
                <h3>{isAdvancedFilterOpen ? "Hide Advance Filter" : "Show Advance Filter"}</h3>
            </div>
            <div className="chevron">
                <img src={Chevron} alt="chevron" style={{transform: isAdvancedFilterOpen ? 'rotate(180deg)' : 'none'}}/>
            </div>
        </div>
</div>

{isAdvancedFilterOpen && (
<div className="open_xcloser">



<div className="row">
<div className="col-md-4">
<h3>Elementary School</h3>
<input 
                            type="text" 
                            className="form-control"
                            name="Enter City or Zip Code"
                            placeholder="Search"
                           
                        />
</div>

<div className="col-md-4">
<h3>Middle School</h3>
<input 
                            type="text" 
                            className="form-control"
                            name="Enter City or Zip Code"
                            placeholder="Search"
                           
                        />
</div>

<div className="col-md-4">
<h3>High School</h3>
<input 
                            type="text" 
                            className="form-control"
                            name="Enter City or Zip Code"
                            placeholder="Search"
                           
                        />
</div>



<div className="col-md-6">
<h3>Status</h3>
<select 
                                id="location" 
                                className="form-select"
                                name="location"
                               
                            >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                            </select>
</div>
<div className="col-md-6">
<h3>Display Property Type</h3>
<select 
                                id="location" 
                                className="form-select"
                                name="location"
                               
                            >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                            </select>
</div>

<div className="col-md-6">
<h3>Property Subtype</h3>
<select 
                                id="location" 
                                className="form-select"
                                name="location"
                               
                            >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                            </select>
</div>
<div className="col-md-6">
<h3>Style</h3>
<select 
                                id="location" 
                                className="form-select"
                                name="location"
                               
                            >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                            </select>
</div>


<div className="col-md-6">
<h3>Special Conditions</h3>
<select 
                                id="location" 
                                className="form-select"
                                name="location"
                               
                            >
                                <option value="location1">Max fee</option>
                                <option value="location2">Location 2</option>
                            </select>
</div>

<div className="col-md-6">
<button type="submit" class="custom_btn search_forming">Search</button>
</div>



</div>
</div>  )}
                </div>
              
                            
                           </div>
                           </form>
                          </div>

                        </div>
                       
                    </div>
                </div>
            </div>
            {/* Backdrop for modal */}
            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
    );
}

export default AdvancedSearch;

  {/*   <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button> */}
