import React from 'react';
import './Welcome.css';


function Welcome() {
    return <div className="welcome">
        <div className="container">
        <div className="row row_man">
        <div className="col-lg-5">
            <div className="welcome_image">
                <h2>Welcome to Wovnn</h2>
            <img src="/assets/welcome.png" className="img-fluid" alt="cta" />
            </div>
        </div>
        <div className="col-lg-7">
            <div className="welcome_text">
           <p> Welcome to the premier resource for all real estate information and services in the area. We hope you enjoy your visit and explore everything our realty website has to offer, including Aloha, Banks, Beaverton, Cornelius, Hillsboro, Newberg, North Plains, Portland, Rockaway Beach, Scappoose, Sherwood, Tigard, Tualatin, Warren, Garibaldi, Manzanita, Tillamook, Bethany, Cedar Hills, Cedar Mill and the Surrounding Areas real estate listings, information for homebuyers and sellers, and more About Us.</p>

<p>Looking for a new home? Use Quick Search or Map Search to browse an up-to-date database list of all available properties in the area, or use our Dream Home Finder form and we'll conduct a personalized search for you.</p>

<p>If you're planning to sell your home in the next few months, nothing is more important than knowing a fair asking price. We would love to help you with a FREE Market Analysis. We will use comparable sold listings to help you determine the accurate market value of your home.</p>
            </div>
        </div>
        </div>

     </div>
     </div>;
}

export default Welcome;
