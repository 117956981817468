
import React  from 'react';

import './PropertySearchResult.css';
import ContactForm from '../../../ContactUs/ContactForm';

function PropertySearchResult() {
   
    const locations = [
        { name: 'Aloha', image: '/assets/properties/l1.png', link: '#' },
        { name: 'Banks', image: '/assets/properties/l2.png', link: '#' },
        { name: 'Beaverton', image: '/assets/properties/l3.png', link: '#' },
        { name: 'Cornelius', image: '/assets/properties/l4.png', link: '#' },
        { name: 'Hillsboro', image: '/assets/properties/l3.png', link: '#' },
        { name: 'Newberg', image: '/assets/properties/l2.png', link: '#' },
        { name: 'North Plains', image: '/assets/properties/l1.png', link: '#' },
        { name: 'Portland', image: '/assets/properties/l4.png', link: '#' },
        { name: 'Rockaway Beach', image: '/assets/properties/l2.png', link: '#' },
        { name: 'Scappoose', image: '/assets/properties/l3.png', link: '#' },
        { name: 'Sherwood', image: '/assets/properties/l1.png', link: '#' },
        { name: 'Tigard', image: '/assets/properties/l4.png', link: '#' },
        { name: 'Tualatin', image: '/assets/properties/l2.png', link: '#' },
        { name: 'Warren', image: '/assets/properties/l4.png', link: '#' },
        { name: 'Garibaldi', image: '/assets/properties/l3.png', link: '#' },
        { name: 'Manzanita', image: '/assets/properties/l1.png', link: '#' },
        { name: 'Tillamook', image: '/assets/properties/l2.png', link: '#' },
        { name: 'Bethany', image: '/assets/properties/l3.png', link: '#' },
        { name: 'Cedar Hills', image: '/assets/properties/l4.png', link: '#' },
        { name: 'Cedar Mills', image: '/assets/properties/l3.png', link: '#' },
       
        
    ];

    return (
        <div className="searchbyarea">
            <div className="overall_location">
                <div className="container">
                    <div className="search_area">
                        <h1>View by Locations</h1>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="location_prop_listing all_location_only d-flex">
                                    {locations.map((location, index) => (
                                        <div key={index} className="single_prop_listing">
                                            <img src={location.image} className="img-fluid" alt="property list" />
                                            <button type="button" className="prop_btn">{location.name}</button>
                                            <div className="overlay">
                                              
                                                <p><a href={location.link}>Under $ 300,000</a></p>
                                                      <p><a href={location.link}> $ 300,000 to 400,000</a></p>
                                                      <p><a href={location.link}> $ 400,000 to 500,000</a></p>
                                                       <p><a href={location.link}> $ 500,000 to 600,000</a></p>
                                                       <p><a href={location.link}> $ 700,000 to 800,000</a></p>
                                                        <p><a href={location.link}> $ 900,000 to 100,000</a></p>
        <p><a href={location.link}> $ 300,000 to 400,000</a></p>
        <p><a href={location.link}> Luxury Homes</a></p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ContactForm /> 


        </div>
    );
}

export default PropertySearchResult;
