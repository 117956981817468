import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from './components/Navigation/Navigation';
import MobileNavigation from './components/Navigation/MobileNavigation';
import HomePage from './components/HomePage/HomePage';
import Footer from './components/Footer/Footer';
import SearchView from './components/SearchPages/SearchView/SearchView';
import PropertyDetail from './components/PropertyDetail/PropertyDetail';
import AboutUs from './components/AboutusPages/AboutUs/AboutUs';
import AgentPage from './components/AboutusPages/AgentsPage/AgentPage';
import ContactUs from './components/ContactUs/ContactUs';
import SingleAgent from './components/AboutusPages/AgentsPage/SingleAgent';
import AllBlogs from './components/Blogs/AllBlogs';
import SingleBlog from './components/Blogs/SingleBlog';
import Forecloures from './components/SearchPages/Forecloures/Forecloures';
import Featured from './components/SearchPages/Featured/Featured';
import MapSearch from './components/SearchPages/MapSearch/MapSearch';
import OpenHouses from './components/SearchPages/OpenHouses/OpenHouses';
import SearchByArea from './components/SearchPages/SearchByArea/SearchByArea';


function App() {
  return (
      <div className="app-container">
          <BrowserRouter>
              <Navigation />
              <MobileNavigation />
              <Routes>
                  <Route path="/" index element={<HomePage />} />
                  <Route path="search-result" element={<SearchView />} />
                  <Route path="forecloures" element={<Forecloures />} />
                  <Route path="featured-listing" element={<Featured />} />
                  <Route path="map-search" element={<MapSearch />} />
                  <Route path="open-houses" element={<OpenHouses />} />
                  <Route path="search-by-area" element={<SearchByArea />} />


                  <Route path="property-detail" element={<PropertyDetail />} />
                  <Route path="about-us" element={<AboutUs />} />
                  <Route path="agents" element={<AgentPage />} />
                  <Route path="single-agent" element={<SingleAgent />} />
                  <Route path="contact-us" element={<ContactUs />} />
                  <Route path="single-blog" element={<SingleBlog />} />
                  <Route path="blogs" element={<AllBlogs />} />
                  
                
              </Routes>
              <Footer />
          </BrowserRouter>
      </div>
  );
}

export default App;


