import React from 'react';
import './Map.css';


function Map() {
    return <div className="gmap">
    <iframe 
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96873.55927017267!2d-74.02739794492494!3d40.64534496917392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24416947c2109%3A0x82765c7404007886!2sBrooklyn%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1695635629907!5m2!1sen!2sin" 
            width="100%" 
            height="423" 
            style={{border: 0}} 
            allowFullScreen="" 
            loading="lazy">
          </iframe>
    </div>;
}

export default Map;
