import React from 'react';
import '../Forecloures/Forecloures.css'

import SearchForm from '../OpenHouses/SearchForm/SearchForm';
import Map from '../OpenHouses/Map/Map';
import PropertySearchResult from '../OpenHouses/PropertySearchResult/PropertySearchResult';










function OpenHouses() {
    return (
        <div className="searchview-container">
            <SearchForm />
            <Map />
            <PropertySearchResult />
           
          
        </div>
    );
}

export default OpenHouses;







