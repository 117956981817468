import React, { useState, useEffect, useRef } from 'react';
import './PropertySearchResult.css';
import Maparrow from '../../../../public/assets/maparrow.svg';

function PropertySearchResult() {
    const [blockCount, setBlockCount] = useState(9);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const rowRef = useRef(null);

    useEffect(() => {
        function handleBlockScroll() {
            const element = rowRef.current;
            
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                setIsAtBottom(true);
            }
        }
    
        if (rowRef.current) {
            rowRef.current.addEventListener('scroll', handleBlockScroll);
        }
    
        return () => {
            if (rowRef.current) {
                rowRef.current.removeEventListener('scroll', handleBlockScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (isAtBottom) {
            setBlockCount(prevCount => prevCount + 3);
            setIsAtBottom(false);
        }
    }, [isAtBottom]);

    const [totalResults, setTotalResults] = useState(0); 
    useEffect(() => {
        fetch('EndpointHere').then(response => response.json()).then(data => {
            setTotalResults(data.total); 
        });
    }, []);

    const handleMapArrowClick = () => {
        setIsCollapsed(!isCollapsed);
    };
   

    return (
        <div className="searched_properties">
            <div className="full_map_area d-flex">
            <div className={`left_map_area ${isCollapsed ? 'collapsed' : ''}`}>
                <div className="gmap">
    <iframe 
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96873.55927017267!2d-74.02739794492494!3d40.64534496917392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24416947c2109%3A0x82765c7404007886!2sBrooklyn%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1695635629907!5m2!1sen!2sin" 
            width="100%" 
            height="800px" 
            style={{border: 0}} 
            allowFullScreen="" 
            loading="lazy">
          </iframe>
    </div>;

    <div className="map_arrow" onClick={handleMapArrowClick}>
                <img src={Maparrow} alt="maparrow" style={{ transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0)' }} />
            </div>
                </div>
         
                <div className={`right_property_listing map_only ${isCollapsed ? 'expanded' : ''}`} ref={rowRef}>
                    
                {Array.from({ length: blockCount }).map((_, idx) => (
                        <PropertyCard key={idx} />
                    ))}
                </div>
            </div>
        </div>
    );
}

function PropertyCard() {
  
    return (
        <div className="full_map_mark">
        <div className="property-card">
                            <div className="property-overlays d-flex">
                                <div className="left-overlays">
                                    <button className="featured">Featured</button>
                                    <button className="featured hot">Hot</button>
                                </div>
                                <div className="right-overlays d-flex">
                                    <div className="right_single_block d-flex">
                                        <div className="left_single_icon">
                                            <img src="/assets/camera.svg" alt="Camera icon" />
                                        </div>
                                        <div className="right_single_text">6</div>
                                    </div>
        
                                    <div className="right_single_block d-flex">
                                        <div className="left_single_icon">
                                            <img src="/assets/video.svg" alt="Video icon" />
                                        </div>
                                        <div className="right_single_text">1</div>
                                    </div>
        
                                    <div className="circle_heart d-flex">
                                        <div className="left_single_icon heart_set">
                                            <img src="/assets/heart.png" alt="Heart icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <img src="/assets/properties/property1.png" alt="Property" />
                            
                            <div className="bottom_property">
                                <h2>Home In Merric Way</h2>
                                <div className="location_flex d-flex">
                                    <div className="location_icon">
                                        <img src="/assets/blue-location.png" alt="Location icon" />
                                    </div>
                                    <div className="location_name">
                                        Merrick Way, Miami, FL 33134, USA
                                    </div>
                                </div>
        
                                <div className="price-amenities d-flex">
                                    <div className="price">
                                        <span className="price-sign">$</span> 540,000
                                    </div>
                                    <div className="amenities d-flex">
                                        <div className="single_amenities d-flex">
                                            <div className="left_amenities">
                                                <img src="/assets/amenties2.png" alt="Amenities icon" />
                                            </div>
                                            <div className="right_amenities">3</div>
                                        </div>
                                        <div className="single_amenities d-flex">
                                            <div className="left_amenities">
                                                <img src="/assets/amenties1.png" alt="Amenities icon" />
                                            </div>
                                            <div className="right_amenities">3</div>
                                        </div>
                                        <div className="single_amenities d-flex">
                                            <div className="left_amenities">
                                                <img src="/assets/amenties3.png" alt="Amenities icon" />
                                            </div>
                                            <div className="right_amenities">
                                                3000 <span className="area">sq ft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
    );
}

export default PropertySearchResult;







