import React, { useState, useEffect, useRef } from 'react';
import './BudgetDropdown.css';
import Dollar from '../../../public/assets/dollar.svg';
import ReactSlider from 'react-slider';



function Dropdown({ placeholder, openDropdown, setOpenDropdown, type, selectedValue, setSelectedValue }) {
    const isEditable = type.includes("Monthly");

    const handleInputChange = (e) => {
        setSelectedValue(e.target.value);
    };

    return (
        <div className="dropdown-container" onClick={e => e.stopPropagation()}>
            <input
                type="text"
                className={`form-control dropdown-input ${type}`}
                placeholder={placeholder}
                value={selectedValue}
                onChange={isEditable ? handleInputChange : null}
                readOnly={!isEditable}
                onClick={() => setOpenDropdown(openDropdown === type ? null : type)}
            />
        </div>
    );
}

function BudgetDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    
    const [selectedMinValuePrice, setSelectedMinValuePrice] = useState('');
    const [selectedMaxValuePrice, setSelectedMaxValuePrice] = useState('');
    const [selectedMinValueMonthly, setSelectedMinValueMonthly] = useState('');
    const [selectedMaxValueMonthly, setSelectedMaxValueMonthly] = useState('');
    
    const dropdownRef = useRef(null);

    const minPriceOptions = [
        'Min',
        '$ 10000',
        '$ 20000',
        '$ 30000',
        '$ 50000',
        '$ 75000',
        '$ 100000',
        '$ +100,000'
    ];

    const maxPriceOptions = [
        'Max',
        '$ 100000',
        '$ 200000',
        '$ 300000',
        '$ 500000',
        '$ 750000',
        '$ 1000000',
        '$ +1000,000'
    ];

    const minMonthlyOptions = minPriceOptions;
    const maxMonthlyOptions = maxPriceOptions;

    const handleDropdownClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, []);

    const [activeTab, setActiveTab] = useState('PRICE'); 
    
    
    /* slider */

    const handleMinChange = value => {
        setSelectedMinValueMonthly(`$ ${value}`);
    }
    
    const handleMaxChange = value => {
        setSelectedMaxValueMonthly(`$ ${value}`);
    }
    

    return (
        <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
            <div className="sel_icon"><img src={Dollar} alt="propertyType" /></div>
            <div className="sel_text">Budget</div>

            {isOpen && (
                <div className="property_drop budget_drop" onClick={handleDropdownClick}>
                    <div className="full_price_filter">
                        <div className="right_swap budget_rad">
                            <div className="left-radio">
                                <input
                                    type="radio"
                                    id="uniqueRadio1"
                                    name="unique-radio-group"
                                    checked={activeTab === 'MONTHLY_PAYMENT'}
                                    onChange={() => setActiveTab('MONTHLY_PAYMENT')}
                                />
                                <label htmlFor="uniqueRadio1" className="unique-radio-label"><span>MONTHLY PAYMENT</span></label>
                            </div>
                            <div className="right-radio">
                                <input
                                    type="radio"
                                    id="uniqueRadio2"
                                    name="unique-radio-group"
                                    checked={activeTab === 'PRICE'}
                                    onChange={() => setActiveTab('PRICE')}
                                />
                                <label htmlFor="uniqueRadio2" className="unique-radio-label"><span>PRICE</span></label>
                            </div>
                        </div>
                        {activeTab === 'PRICE' && (
                        <div className="two_input_blocks d-flex square_ft price_data">
                            <div className="left_input_blocks">
                                <Dropdown 
                                    placeholder="Min Price"
                                    openDropdown={openDropdown}
                                    setOpenDropdown={setOpenDropdown}
                                    type="min"
                                    selectedValue={selectedMinValuePrice}
                                    setSelectedValue={setSelectedMinValuePrice}
                                />
                            </div>
                            <div className="right_input_blocks">
                                <Dropdown 
                                    placeholder="Max Price"
                                    openDropdown={openDropdown}
                                    setOpenDropdown={setOpenDropdown}
                                    type="max"
                                    selectedValue={selectedMaxValuePrice}
                                    setSelectedValue={setSelectedMaxValuePrice}
                                />
                            </div>
                        </div>
                    )}
                    {activeTab === 'MONTHLY_PAYMENT' && (
                        <div className="two_input_blocks d-flex square_ft price_data">
                            <div className="left_input_blocks">
                                <Dropdown 
                                    placeholder="Min Price"
                                    openDropdown={openDropdown}
                                    setOpenDropdown={setOpenDropdown}
                                    type="minMonthly"
                                    selectedValue={selectedMinValueMonthly}
                                    setSelectedValue={setSelectedMinValueMonthly}
                                />
  <ReactSlider
    className="horizontal-slider"
    thumbClassName="thumb"
    trackClassName="track"
    min={10000}
    max={100000} 
    value={Number(selectedMinValueMonthly.replace(/[^0-9.-]+/g,""))}
    onChange={handleMinChange}
/>


                            </div>
                            <div className="right_input_blocks">
                                <Dropdown 
                                    placeholder="Max Price"
                                    openDropdown={openDropdown}
                                    setOpenDropdown={setOpenDropdown}
                                    type="maxMonthly"
                                    selectedValue={selectedMaxValueMonthly}
                                    setSelectedValue={setSelectedMaxValueMonthly}
                                />
                         <ReactSlider
    className="horizontal-slider"
    thumbClassName="thumb"
    trackClassName="track"
    min={10000} 
    max={1000000}
    value={Number(selectedMaxValueMonthly.replace(/[^0-9.-]+/g,""))}
    onChange={handleMaxChange}
/>




                            </div>
                        </div>
                    )}


<div className="common-options-container">
    {activeTab === 'PRICE' && openDropdown === 'min' && renderOptions(minPriceOptions, setSelectedMinValuePrice)}
    {activeTab === 'PRICE' && openDropdown === 'max' && renderOptions(maxPriceOptions, setSelectedMaxValuePrice)}

    {activeTab === 'MONTHLY_PAYMENT' && openDropdown === 'minMonthly' && renderOptions(minMonthlyOptions, setSelectedMinValueMonthly)}
    {activeTab === 'MONTHLY_PAYMENT' && openDropdown === 'maxMonthly' && renderOptions(maxMonthlyOptions, setSelectedMaxValueMonthly)}
</div>
                    </div>
                </div>
            )}
        </div>
    );

    function renderOptions(options, setValueFunction) {
        return options.map((option, index) => (
            <div
                key={index}
                className={`Priceoption ${option.includes('Min') || option.includes('Max') ? 'min-max-option' : ''}`}
                onClick={() => {
                    setValueFunction(option);
                    setOpenDropdown(null);
                }}
        >
                {option.includes('$') ? (
                    <>
                        <span className="dollar-sign">$</span>
                        <span>{option.replace('$', '').trim()}</span>
                    </>
                ) : (
                    option
                )}
            </div>
        ));
    }
}

export default BudgetDropdown;
