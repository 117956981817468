import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

import '../../AboutusPages/AboutUsPages.css';
import Back from '../../../public/assets/back.svg';
import Agent from '../../../public/assets/agent.png';
import AgentIc from '../../../public/assets/agent-phone.svg';
import AgentIc1 from '../../../public/assets/agent-message.svg';

import C1 from '../../../public/assets/c1.svg';
import C2 from '../../../public/assets/c2.svg';
import C3 from '../../../public/assets/c3.svg';
import Sliders from '../../../public/assets/s3.svg';

import listinglogo from '../../../public/logo.png';



function SingleAgent() {

    /* tabs */

    const [activeTab, setActiveTab] = useState('activeListings'); 

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };
    
     /* tabs */

/* Dropdown */
const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState('Price (Low to High)');

const AgentdropdownOptions = [
    'Price (Low to High)',
    'Price (High to Low)',
    'Newest',
    'Oldest',
    'Sq Ft',
    'Lot Size',
];

const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
};

const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
};



    return (
        <div className="single-agent-full">
            <div className="container">
            <div className="row">
            <div className="col-lg-12">
            <div className="back_page">
                <Link to="/agents" className="breadcrumb-link">
                    <img src={Back} alt='Back Arrow' />
                    back to page
                </Link>
            </div>


<div className="single_agent_info">
<img src={Agent} className="img-fluid"/>
<h1>Annette Black</h1>

<div className="flex_infomation d-flex">
<div className="lest_agt_info">Real Estate Agent , Country House Real Estate</div>
<div className="rt_agt_info d-flex">
<div className="rt_icons"><img src={AgentIc} className="img-fluid"/>503-703-8860</div>
<div className="rt_icons"><img src={AgentIc1} className="img-fluid"/> nalbro@comcast.net</div>
</div>

</div>
<p>As a dedicated and knowledgeable real estate agent I can provide you with the highly specialized information that you will need to make the right decision. Its the combination of this unique market-knowledge and excellent negotiating skills that enable me to get you the optimal price for any property you plan to sell or buy.
As real estate agent in the Portland area and North Oregon coast beaches, I can provide you with helpful insights about the area, and assist you in making the right choices for your next home. With a professional yet friendly approach, I help you feel comfortable about what can be a stressful decision.
Trust is hard to come by in today's fiercely competitive real estate market. I am here to provide you with the professionalism and integrity needed to ensure that you feel secure in what can be one of the biggest decisions of your life.</p>

</div>

<div className="agent_counter d-flex">
                <div className="single_count">
                    <img src={C1} className="img-fluid" alt='Total Deals'/>
                    <h2><CountUp end={193} duration={2} /></h2>
                    <h3>TOTAL DEALS AT WOVNN</h3>
                </div>
                <div className="single_count">
                    <img src={C2} className="img-fluid" alt='Deal Volume'/>
                    <h2>$<CountUp end={113} duration={2} />M</h2>
                    <h3>DEAL VOLUME</h3>
                </div>
                <div className="single_count">
                    <img src={C3} className="img-fluid" alt='Highest Deal Price'/>
                    <h2>$<CountUp end={1.8} decimals={1} duration={2} />M</h2>
                    <h3>HIGHEST DEAL PRICE</h3>
                </div>
            </div>
               
            </div>
            </div>


<div className="row">
<div className="col-lg-4">


<div className="overall_property_tabs">
                        <div className="property_tab d-flex">
                            <div
                                className={`left_prop_tab ${activeTab === 'activeListings' ? 'active' : ''}`}
                                onClick={() => handleTabChange('activeListings')}
                            >
                                Active Listing (4)
                            </div>
                            <div
                                className={`left_prop_tab right_prop_tab ${activeTab === 'soldProperties' ? 'active' : ''}`}
                                onClick={() => handleTabChange('soldProperties')}
                            >
                                Sold Properties (51)
                            </div>
                        </div>

                        {activeTab === 'activeListings' && (
                            <div className="prop_tabs_data1">
                              <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                   
                  <img src="/assets/properties/property2.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>

                  <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property2.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
                            </div>
                        )}
                        {activeTab === 'soldProperties' && (
                            <div className="prop_tabs_data2">
                                       <div className="property-card">
                    <div className="property-overlays d-flex">
                    <div className="left-overlays">
                        <button className="featured">Featured</button>
                        <button className="featured hot">Hot</button>
                    </div>
                    <div className="right-overlays d-flex">

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/camera.svg" alt="Wovnn camera-icon" /></div>
                    <div className="right_single_text">6</div>
                    </div>

                    <div className="right_single_block d-flex">
                    <div className="left_single_icon"><img src="/assets/video.svg" alt="Wovnn video-icon" /></div>
                    <div className="right_single_text">1</div>
                    </div>

                    <div className="circle_heart d-flex">
                    <div className="left_single_icon heart_set"><img src="/assets/heart.png" alt="Wovnn heart-icon" /></div>
                    
                    </div>
                    </div>
                    </div>
                    <div className="flex_overal">
<div className="first_flex_overaly">   Single Family</div>
                    <div className="second_flex_overlay"><img src={listinglogo} className="img-fluid"/></div>

                    </div>
                  <img src="/assets/properties/property2.png" alt="Wovnn propertyies" />
                  <div className="bottom_property">
                  <h2>Home In Merric Way</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Merrick Way, Miami, FL 33134, USA</div>
                  </div>

                  <div className="price-amenities d-flex">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
                    <div className="amenities d-flex">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>
                  </div>
                  </div>

                  </div>
                            </div>
                        )}
                    </div>


</div>
<div className="col-lg-8">

<div className="overall_right_side">

    <div className="agent_dropdown">
        <div className={`drop_data ${isDropdownOpen ? 'open' : ''}`}>
            <div
                className="selected_option"
                onClick={toggleDropdown}
                style={{ backgroundImage: `url(${Sliders})` }}
            >
                {selectedOption}
            </div>
            {isDropdownOpen && (
                <div className="drop_selection">
                    {AgentdropdownOptions.map((option, index) => (
                        <p key={index} onClick={() => handleOptionSelect(option)}>
                            {option}
                        </p>
                    ))}
                </div>
            )}
        </div>
    </div>

<div className="agent_map">
<iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.7104613024653!2d-122.8828031!3d45.495775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950e491f21ed19%3A0x2c833f8ef7c511b4!2s20001%20SW%20Tualatin%20Valley%20Hwy%2C%20Beaverton%2C%20OR%2097006%2C%20USA!5e0!3m2!1sen!2sin!4v1698298246128!5m2!1sen!2sin" 
        width="100%" 
        height="800" 
        style={{border: "0"}}
        allowFullScreen 
        loading="lazy"
        title="Location Map"
        referrerPolicy="no-referrer-when-downgrade">
      </iframe>

</div>



</div>


</div>

</div>




            </div>
           
        </div>
    );
}

export default SingleAgent;
