import React from 'react';
import { Link } from 'react-router-dom';
import './ViewByLocation.css';


function ViewByLocation() {
    return <div className="overall_location"><div className="container">
        <div className="viewbylocatioon">

<h2>View by Locations</h2>

<Link className="all_locations" to="/search-by-area">
                View All Locations
            </Link>

<div className="row">
<div className="col-lg-12">



<div className="location_prop_listing d-flex">

<div className="single_prop_listing">
    <img src="/assets/properties/l1.png" className="img-fluid" alt="property list" />
    <button type="button" className="prop_btn">Aloha</button>
    <div className="overlay">
        <p><a href="your-link-here">Under $ 300,000</a></p>
        <p><a href="your-link-here"> $ 300,000 to 400,000</a></p>
        <p><a href="your-link-here"> $ 400,000 to 500,000</a></p>
        <p><a href="your-link-here"> $ 500,000 to 600,000</a></p>
        <p><a href="your-link-here"> $ 700,000 to 800,000</a></p>
        <p> <a href="your-link-here"> $ 900,000 to 100,000</a></p>
        <p><a href="your-link-here"> $ 300,000 to 400,000</a></p>
        <p><a href="your-link-here"> Luxury Homes</a></p>
        
    </div>
</div>


<div className="single_prop_listing">
    <img src="/assets/properties/l2.png" className="img-fluid" alt="property list" />
    <button type="button" className="prop_btn">Banks</button>
    <div className="overlay">
    <p><a href="your-link-here">Under $ 300,000</a></p>
        <p><a href="your-link-here"> $ 300,000 to 400,000</a></p>
        <p><a href="your-link-here"> $ 400,000 to 500,000</a></p>
        <p><a href="your-link-here"> $ 500,000 to 600,000</a></p>
        <p><a href="your-link-here"> $ 700,000 to 800,000</a></p>
        <p> <a href="your-link-here"> $ 900,000 to 100,000</a></p>
        <p><a href="your-link-here"> $ 300,000 to 400,000</a></p>
        <p><a href="your-link-here"> Luxury Homes</a></p>
        
    </div>
</div>


<div className="single_prop_listing">
    <img src="/assets/properties/l3.png" className="img-fluid" alt="property list" />
    <button type="button" className="prop_btn">Beaverton</button>
    <div className="overlay">
    <p><a href="your-link-here">Under $ 300,000</a></p>
        <p><a href="your-link-here"> $ 300,000 to 400,000</a></p>
        <p><a href="your-link-here"> $ 400,000 to 500,000</a></p>
        <p><a href="your-link-here"> $ 500,000 to 600,000</a></p>
        <p><a href="your-link-here"> $ 700,000 to 800,000</a></p>
        <p> <a href="your-link-here"> $ 900,000 to 100,000</a></p>
        <p><a href="your-link-here"> $ 300,000 to 400,000</a></p>
        <p><a href="your-link-here"> Luxury Homes</a></p>
        
    </div>
</div>


<div className="single_prop_listing">
    <img src="/assets/properties/l4.png" className="img-fluid" alt="property list" />
    <button type="button" className="prop_btn">Cornelius</button>
    <div className="overlay">
    <p><a href="your-link-here">Under $ 300,000</a></p>
        <p><a href="your-link-here"> $ 300,000 to 400,000</a></p>
        <p><a href="your-link-here"> $ 400,000 to 500,000</a></p>
        <p><a href="your-link-here"> $ 500,000 to 600,000</a></p>
        <p><a href="your-link-here"> $ 700,000 to 800,000</a></p>
        <p> <a href="your-link-here"> $ 900,000 to 100,000</a></p>
        <p><a href="your-link-here"> $ 300,000 to 400,000</a></p>
        <p><a href="your-link-here"> Luxury Homes</a></p>
        
    </div>
</div>

</div>
</div>

</div>

</div> </div></div>;
}

export default ViewByLocation;
