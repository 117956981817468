
import React from 'react';
import './JustListed.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';




function JustListed() {
    return (
      <div className="container just_listed">
<div className="row">
<div className="col-md-12">
<h2 className="recent_listed text-center">Just Listed Homes for Sale in Beaverton, OR</h2>

    </div></div>
    <div className="row">  
        <div className="col-lg-12"> 
        <button className=" all_locations">View All Locations </button>
       
        </div>
        </div>

<div className="row">
<div className="col-md-6">
              
              <div className="property-card d-flex">
            
<div className="left_property_image"><img src="/assets/properties/property2.png" alt="Wovnn propertyies" /></div>
              
              <div className="bottom_property">
              <h2>Villa on Hollywood Boulevard</h2>
              <div className="location_flex d-flex">
                <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                <div className="location_name">Hatteras Lane, Hollywood, FL 33019, USA</div>
              </div>
              <div className="amenities d-flex custom_properties">
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                 </div>

                </div>

              <div className="price-amenities">
                <div className="price"><span className="price-sign">$</span> 540,000</div>
          
              </div>
              </div>

              </div>
           
            </div>
            
                <div className="col-md-6">
              
                  <div className="property-card d-flex">
                
<div className="left_property_image"><img src="/assets/properties/property1.png" alt="Wovnn propertyies" /></div>
                  
                  <div className="bottom_property">
                  <h2>Villa on Hollywood Boulevard</h2>
                  <div className="location_flex d-flex">
                    <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                    <div className="location_name">Hatteras Lane, Hollywood, FL 33019, USA</div>
                  </div>
                  <div className="amenities d-flex custom_properties">
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3</div>
                     </div>
                     <div className="single_amenities d-flex">
                     <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                     <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                     </div>

                    </div>

                  <div className="price-amenities">
                    <div className="price"><span className="price-sign">$</span> 540,000</div>
              
                  </div>
                  </div>

                  </div>
               
                </div>


                <div className="col-md-6">
              
              <div className="property-card d-flex">
            
<div className="left_property_image"><img src="/assets/properties/property3.png" alt="Wovnn propertyies" /></div>
              
              <div className="bottom_property">
              <h2>Villa on Hollywood Boulevard</h2>
              <div className="location_flex d-flex">
                <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                <div className="location_name">Hatteras Lane, Hollywood, FL 33019, USA</div>
              </div>
              <div className="amenities d-flex custom_properties">
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                 </div>

                </div>

              <div className="price-amenities">
                <div className="price"><span className="price-sign">$</span> 540,000</div>
          
              </div>
              </div>

              </div>
           
            </div>


            <div className="col-md-6">
              
              <div className="property-card d-flex">
            
<div className="left_property_image"><img src="/assets/properties/property2.png" alt="Wovnn propertyies" /></div>
              
              <div className="bottom_property">
              <h2>Villa on Hollywood Boulevard</h2>
              <div className="location_flex d-flex">
                <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                <div className="location_name">Hatteras Lane, Hollywood, FL 33019, USA</div>
              </div>
              <div className="amenities d-flex custom_properties">
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                 </div>

                </div>

              <div className="price-amenities">
                <div className="price"><span className="price-sign">$</span> 540,000</div>
          
              </div>
              </div>

              </div>
           
            </div>


            <div className="col-md-6">
              
              <div className="property-card d-flex">
            
<div className="left_property_image"><img src="/assets/properties/property1.png" alt="Wovnn propertyies" /></div>
              
              <div className="bottom_property">
              <h2>Villa on Hollywood Boulevard</h2>
              <div className="location_flex d-flex">
                <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                <div className="location_name">Hatteras Lane, Hollywood, FL 33019, USA</div>
              </div>
              <div className="amenities d-flex custom_properties">
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                 </div>

                </div>

              <div className="price-amenities">
                <div className="price"><span className="price-sign">$</span> 540,000</div>
          
              </div>
              </div>

              </div>
           
            </div>


            <div className="col-md-6">
              
              <div className="property-card d-flex">
            
<div className="left_property_image"><img src="/assets/properties/property3.png" alt="Wovnn propertyies" /></div>
              
              <div className="bottom_property">
              <h2>Villa on Hollywood Boulevard</h2>
              <div className="location_flex d-flex">
                <div className="location_icon">  <img src="/assets/blue-location.png" alt="Wovnn lovation-icon" /></div>
                <div className="location_name">Hatteras Lane, Hollywood, FL 33019, USA</div>
              </div>
              <div className="amenities d-flex custom_properties">
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties2.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties1.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3</div>
                 </div>
                 <div className="single_amenities d-flex">
                 <div className="left_amenities"><img src="/assets/amenties3.png" alt="Wovnn amenities-icon" /></div>
                 <div className="right_amenities">3000 <span className="area">sq ft</span></div>
                 </div>

                </div>

              <div className="price-amenities">
                <div className="price"><span className="price-sign">$</span> 540,000</div>
          
              </div>
              </div>

              </div>
           
            </div>
                
              </div>



      
      </div>
    );
  }
  
  
  export default JustListed;