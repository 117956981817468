import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import LoginProcessModal from './LoginProcessModal';
import './Navigation.css';
import FreeMarketAnalysis from '../AboutusPages/FreeMarketAnalysis/FreeMarketAnalysis';
import DreamHomeFinder from '../AboutusPages/DreamHomeFinder/DreamHomeFinder';


function MobileNavigation() {
    const [isOpen, setIsOpen] = useState(false);
    const [openedDropdown, setOpenedDropdown] = useState(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown = (dropdownId) => {
        setOpenedDropdown(openedDropdown === dropdownId ? null : dropdownId);
    };
    /* Modal */
const [showModal, setShowModal] = useState(false);
/* Modal */
const [showFreeMarketAnalysisModal, setShowFreeMarketAnalysisModal] = useState(false);
const [showDreamHomeFinderModal, setShowDreamHomeFinderModal] = useState(false);

    return (
        <div className="mobile-nav">
            <div className="mobile-logo">
                <img src="/assets/logo.png" alt="Woovn Logo" />
            </div>
            <button className="mobile-menu-btn" onClick={toggleMenu}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
            </button>

            {isOpen && (
                <div className="mobile-menu-content">
                    {/* Search Dropdown */}
                    <div className="dropdown" onClick={() => toggleDropdown('search')}>
                        Search <FontAwesomeIcon icon={faChevronDown} />
                        {openedDropdown === 'search' && (
                              <div className="dropdown-content">
                          
                              
                                <Link  className="mobile-link" to="/forecloures">Forecloures</Link>
                                <Link  className="mobile-link" to="/open-houses">Open House</Link>
                                <Link  className="mobile-link" to="/featured-listing">Featured Listing</Link>
                                <Link  className="mobile-link" to="/map-search">Map Search</Link>
                                <Link  className="mobile-link" to="/search-by-area">Search By Area</Link>
                                <Link  className="mobile-link" to="/advanced-search">Advanced Search</Link>
                            
                            </div>
                        )}
                    </div>

                    {/* Resources Dropdown */}
                    <div className="dropdown" onClick={() => toggleDropdown('resources')}>
                        Resources <FontAwesomeIcon icon={faChevronDown} />
                        {openedDropdown === 'resources' && (
                             <div className="dropdown-content">
                           
                                {/* Update these links to reflect actual 'Resources' content */}
                                <a href="#" className="mobile-link">Tips For Buyers</a>
                                <a href="#" className="mobile-link">Tips For Sellers</a>

                                <a href="#" className="mobile-link">Homeowner</a>
                                <a href="#" className="mobile-link">Title & Escrow</a>
                                <a href="#" className="mobile-link">Mortgage</a>
                                <a href="#" className="mobile-link">Mortgage Rates</a>
                                <a href="#" className="mobile-link">Schools</a>
                                <a href="#" className="mobile-link">Relocations</a>
                                <a href="#" className="mobile-link">Weather</a>
                                <a href="#" className="mobile-link">Real Estate Glossary</a>
                                </div>
                            
                        )}
                    </div>

                    {/* About Dropdown */}
                    <div className="dropdown" onClick={() => toggleDropdown('about')}>
    <span>About</span>
    <FontAwesomeIcon icon={faChevronDown} />
    {openedDropdown === 'about' && (
       <div className="dropdown-content">
            {/* Update these links to reflect actual 'About' content */}
            <Link  className="mobile-link" to="/about-us">About Us</Link>

            
            <Link  className="mobile-link" to="/agents">Our Agents</Link>
            <Link  className="mobile-link" to="/blogs">Our Blogs</Link>
           
            <a className="mobile-link" href="#" onClick={() => setShowFreeMarketAnalysisModal(true)}>Free Market Analysis</a>

            <a href="#" className="mobile-link"onClick={() => setShowDreamHomeFinderModal(true)}>Dream Home Finder</a>
       </div>
    )}
</div>


                   <Link  className="mobile-link" to="/contact-us">Contact</Link>
                    <li className="nav-item logins">
                    <span className="nav-link cur" data-bs-toggle="modal" data-bs-target="#loginModal">
        <img src="/assets/login.png" alt="login" />
      </span>

      <LoginProcessModal />
                    </li>
                    <FreeMarketAnalysis showModal={showFreeMarketAnalysisModal} setShowModal={setShowFreeMarketAnalysisModal} /> 
        <DreamHomeFinder showModal={showDreamHomeFinderModal} setShowModal={setShowDreamHomeFinderModal} />
                </div>
                
                
            )}
            
        </div> 
        
        
    );
}


export default MobileNavigation;
