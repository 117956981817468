import React, { useState, useEffect, useRef } from 'react';
import './PropertyTypeDropdown.css';
import Building from '../../../public/assets/Building.svg';
import Chevron from '../../../public/assets/chevron.svg';

function PropertyTypeDropdown() {
    // State variables to manage dropdown logic
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCommercialOpen, setIsCommercialOpen] = useState(true); 
    
    // Ref to the dropdown for detecting clicks outside
    const dropdownRef = useRef(null); 

    /**
     * Toggles the selection of the dropdown options.
     */
    const toggleSelection = (event, option) => {
        event.stopPropagation();
        if (selectedOptions.includes(option)) {
            setSelectedOptions(prevOptions => prevOptions.filter(item => item !== option));
        } else {
            setSelectedOptions(prevOptions => [...prevOptions, option]);
        }
    };

    /**
     * Renders the dropdown options based on the provided list.
     */
    const renderOptions = (options) => {
        return options.map((option) => (
            <div 
                key={option}
                className={`option ${selectedOptions.includes(option) ? 'selected' : ''}`} 
                onClick={(event) => toggleSelection(event, option)}
            >
                {option}
            </div>
        ));
    };

    /**
     * Effect to handle clicks outside the dropdown to close it.
     */
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        // Attach the event listener
        document.addEventListener('click', handleOutsideClick);

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, []);

    return (
        <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
            <div className="sel_icon"><img src={Building} alt="propertyType" /></div>
            <div className="sel_text">Property Type</div>
            
            {isOpen && (
                <div className="property_drop">
                    <h3>Residential</h3>
                    <div className="options">
                        {renderOptions(['Houses', 'Townhomes', 'Multi-Family'])}
                    </div>
                    <div className='comercial_block d-flex' onClick={(e) => { 
                        e.stopPropagation(); 
                        setIsCommercialOpen(!isCommercialOpen);
                    }}>
                        <div className="left_commerc">
                            <h3>Commercial</h3>
                        </div>
                        <div className="chevron">
                            <img src={Chevron} alt="chevron" style={isCommercialOpen ? {} : {transform: 'rotate(180deg)'}} /> 
                        </div>
                    </div>
                    {isCommercialOpen && (
                        <div className="options">
                            {renderOptions(['Condos/Co-ops','Lots/Land', 'Apartments', 'Manufactured'])}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default PropertyTypeDropdown;
