import React from 'react';
import './SearchView.css';

import SearchForm from './SearchForm/SearchForm';
import Map from './Map/Map';
import PropertySearchResult from './PropertySearchResult/PropertySearchResult';










function SearchView() {
    return (
        <div className="searchview-container">
            <SearchForm />
            <Map />
            <PropertySearchResult />
           
          
        </div>
    );
}

export default SearchView;







