import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Navigation.css';
import { useLocation } from 'react-router-dom'; 
import LoginProcessModal from './LoginProcessModal';
import FreeMarketAnalysis from '../AboutusPages/FreeMarketAnalysis/FreeMarketAnalysis';
import DreamHomeFinder from '../AboutusPages/DreamHomeFinder/DreamHomeFinder';
import AdvancedSearch from '../AboutusPages/AdvancedSearch/AdvancedSearch';




function Navigation() {
    const location = useLocation(); 
           const isHomepage = location.pathname === '/';
/* Modal */
const [showFreeMarketAnalysisModal, setShowFreeMarketAnalysisModal] = useState(false);
const [showDreamHomeFinderModal, setShowDreamHomeFinderModal] = useState(false);
const [showAdvancedSearch, setShowAdvancedSearchModal] = useState(false);

    
    return (
        <div className={`overall_nav ${isHomepage ? 'homepage' : 'otherpage'}`}>
        <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light custom_navi ">
            <a className="navbar-brand" href="/"><img src="/assets/logo.png" alt="Woovn Logo" />
</a>
<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
</button>

<div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown">
                            Search
                        </a>
                        <div className="dropdown-menu">
                            
                            <Link className="dropdown-item" to="/forecloures">Forecloures</Link>
                            <Link className="dropdown-item" to="/open-houses">Open House</Link>
                            <Link className="dropdown-item" to="/featured-Listing">Featured Listing</Link>
                            <Link className="dropdown-item" to="/map-search">Map Search</Link>
                            <Link className="dropdown-item" to="/search-by-area">Search By Area</Link>
                            <a className="dropdown-item" href="#" onClick={() => setShowAdvancedSearchModal(true)}>Advanced Search</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown">
                            Resources
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" href="#">Tips For Buyers</a>
                            <a className="dropdown-item" href="#">Tips For Sellers</a>

                            <a className="dropdown-item" href="#">Homeowner</a>
                            <a className="dropdown-item" href="#">Title & Escrow</a>
                            <a className="dropdown-item" href="#">Mortgage</a>
                            <a className="dropdown-item" href="#">Mortgage Rates</a>
                            <a className="dropdown-item" href="#">Schools</a>
                            <a className="dropdown-item" href="#">Relocations</a>
                            <a className="dropdown-item" href="#">Weather</a>
                            <a className="dropdown-item" href="#">Real Estate Glossary</a>
                            
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-bs-toggle="dropdown">
                            About
                        </a>
                        <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/about-us">About Us</Link>
                        <Link className="dropdown-item" to="/agents">Our Agents</Link>
                        <Link className="dropdown-item" to="/blogs">Our Blogs</Link>
                        <a className="dropdown-item" href="#" onClick={() => setShowFreeMarketAnalysisModal(true)}>Free Market Analysis</a>
                          
                         <a className="dropdown-item" href="#" onClick={() => setShowDreamHomeFinderModal(true)}>Dream Home Finder</a>
                       
                          
                        </div>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">Contact Us</Link>
                    </li>
                    <li className="nav-item logins">
                    <span className="nav-link cur" data-bs-toggle="modal" data-bs-target="#loginModal">
        <img src="/assets/login.png" alt="login" />
      </span>

      <LoginProcessModal />
                    </li>
                </ul>
            </div>
        </nav>
        </div>

        
        <FreeMarketAnalysis showModal={showFreeMarketAnalysisModal} setShowModal={setShowFreeMarketAnalysisModal} /> 
        <DreamHomeFinder showModal={showDreamHomeFinderModal} setShowModal={setShowDreamHomeFinderModal} />
        <AdvancedSearch showModal={showAdvancedSearch} setShowModal={setShowAdvancedSearchModal} />
        </div>

        
    );
}
export default Navigation;
