import React from 'react';
import './SpaceType.css';



function SpaceType() {
    return <div className="space-type">
  <div className="container">
      <div className="row">
      <div className="col-lg-3 col-sm-12 col-md-6">
        <div className="image_text">
<h3>Villa</h3>
<p>Lorem ipsum dolor sit amet,
consectetur adipiscing elit, sed do
eiusmod tempor incidi dunt</p>
</div> 
<div className="image_blocks imageblock1">
    <div className="property-details">
        <span className="small-text">23 Properties</span>
        <h2>Apartment</h2>
    </div>
    <div className="bottom-links">
        <a href="#" className="more-properties">More properties</a>
        <span className="play-icon"> <img src="/assets/play.svg" className="img-fluid" alt="play" /></span> 
    </div>
</div>


      </div>
      <div className="col-lg-3 col-sm-12 col-md-6">
       
<div className="image_blocks imageblock2">
    <div className="property-details">
        <span className="small-text">07 Properties</span>
        <h2>Studio</h2>
    </div>
    <div className="bottom-links">
        <a href="#" className="more-properties">More properties</a>
        <span className="play-icon"> <img src="/assets/play.svg" className="img-fluid" alt="play" /></span> 
    </div>
</div>


      </div>


      <div className="col-lg-3 col-sm-12 col-md-6">
       
      <div className="image_blocks imageblock3">
    <div className="property-details">
        <span className="small-text">12 Properties</span>
        <h2>Single Family Home</h2>
    </div>
    <div className="bottom-links">
        <a href="#" className="more-properties">More properties</a>
        <span className="play-icon"> <img src="/assets/play.svg" className="img-fluid" alt="play" /></span> 
    </div>
</div>
<div className="image_blocks imageblock4">
    <div className="property-details">
        <span className="small-text">23 properties</span>
        <h2>Villa</h2>
    </div>
    <div className="bottom-links">
        <a href="#" className="more-properties">More properties</a>
        <span className="play-icon"> <img src="/assets/play.svg" className="img-fluid" alt="play" /></span> 
    </div>
</div>
       
       
             </div>

             
      <div className="col-lg-3 col-sm-12 col-md-6">
       
       <div className="image_blocks imageblock5">
     <div className="property-details">
         <span className="small-text">35 properties</span>
         <h2>Office</h2>
     </div>
     <div className="bottom-links">
         <a href="#" className="more-properties">More properties</a>
         <span className="play-icon"> <img src="/assets/play.svg" className="img-fluid" alt="play" /></span> 
     </div>
 </div>
 <div className="image_blocks imageblock6">
     <div className="property-details">
         <span className="small-text">03 properties</span>
         <h2>Shop</h2>
     </div>
     <div className="bottom-links">
         <a href="#" className="more-properties">More properties</a>
         <span className="play-icon"> <img src="/assets/play.svg" className="img-fluid" alt="play" /></span> 
     </div>
 </div>
        
        
              </div>

       

      </div>
    </div>
     </div>;
}

export default SpaceType;
