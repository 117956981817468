import React, { useState } from 'react';

import './ContactUs.css';
import Agents from '../HomePage/Agents/Agents';
import CtaOne from '../HomePage/CtaOne/CtaOne'; 
import ContactForm from './ContactForm';



function ContactUs() {

 

    const [formData, setFormData] = useState({
        inquiryType: 'select',
        information: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        location: 'location1',
        zipcode: '',
        propertyType: 'type1',
        budget: 'budget1',
        gdprAgreement: false
    });
    
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        
        console.log(formData);
        alert('Data ready to be sent: ' + JSON.stringify(formData));
    
    
        
    };
    
    
    /* property-dropdown-data */
    const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
    const [propertyFeaturesOpen, setPropertyFeaturesOpen] = useState(false);
    const [budgetOpen, setBudgetOpen] = useState(false);
    const [lifestyleOpen, setLifestyleOpen] = useState(false);
    
    const closeAllDropdowns = () => {
    setPropertyTypeOpen(false);
    setPropertyFeaturesOpen(false);
    setBudgetOpen(false);
    setLifestyleOpen(false);
    };
    
    const handleDocumentClick = (e) => {
    if (
        !e.target.closest('.select_custom') &&
        (propertyTypeOpen || propertyFeaturesOpen || budgetOpen || lifestyleOpen)  
    ) {
        closeAllDropdowns();
    }
    };
    
    
    document.addEventListener('click', handleDocumentClick);
    



    return (
        <div className="full_contactus">
  
<div className="container-fluid">
    <div className="row d_mis">
        <div className="col-lg-6">

<div className="contact_text">
<h1>Your Inquiry, Our Commitment to Excellence</h1>
<p>Welcome to the premier resource for all real estate information and services in the area. We hope you enjoy your visit and explore everything our realty website has to offer, including Aloha, Banks, Beaverton, Cornelius, Hillsboro, Newberg, North Plains, Portland, Rockaway Beach, Scappoose, Sherwood, Tigard, Tualatin, Warren, Garibaldi, Manzanita, Tillamook, Bethany, Cedar Hills, Cedar Mill and the Surrounding Areas real estate listings, information for homebuyers and sellers, and more About Us.</p>

<p>Looking for a new home? Use Quick Search or Map Search to browse an up-to-date database list of all available properties in the area, or use our Dream Home Finder form and we'll conduct a personalized search for you.</p>

</div>

        </div>
        <div className="col-lg-6">
        <div className="location_map">
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.7104613024653!2d-122.8828031!3d45.495775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950e491f21ed19%3A0x2c833f8ef7c511b4!2s20001%20SW%20Tualatin%20Valley%20Hwy%2C%20Beaverton%2C%20OR%2097006%2C%20USA!5e0!3m2!1sen!2sin!4v1698298246128!5m2!1sen!2sin" 
        width="100%" 
        height="450" 
        style={{border: "0"}}
        allowFullScreen 
        loading="lazy"
        title="Location Map"
        referrerPolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
        </div>
    </div>
</div>


<div className='contact_cta'>
<CtaOne /> 
</div>

<ContactForm /> 


<div className="about_team">

 <Agents /> 
</div>









</div>





    );
}

export default ContactUs;

