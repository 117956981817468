

import React, { useState, useEffect } from 'react';
import './SearchForm.css';
 import SearchImage1 from '../../../../public/assets/s1.svg';
import SearchImage2 from '../../../../public/assets/s2.svg'; 
import SearchImage3 from '../../../../public/assets/s3.svg';
import PropertyTypeDropdown from '../../../ReusableComponents/PropertyTypeDropdown/PropertyTypeDropdown';
import PropertyFeaturesDropdown from '../../../ReusableComponents/PropertyFeaturesDropdown/PropertyFeaturesDropdown';
import BudgetDropdown from '../../../ReusableComponents/BudgetDropdown/BudgetDropdown';
import LifeStyleSearchDropdown from '../../../ReusableComponents/LifestyleSearchDropdown/LifeStyleSearchDropdown';

function SearchForm() {
    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 50); 
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

/* mutual search functions */




    const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
    const [propertyFeaturesOpen, setPropertyFeaturesOpen] = useState(false);
    const [budgetOpen, setBudgetOpen] = useState(false);
    const [lifestyleOpen, setLifestyleOpen] = useState(false);

    const closeAllDropdowns = () => {
        setPropertyTypeOpen(false);
        setPropertyFeaturesOpen(false);
        setBudgetOpen(false);
        setLifestyleOpen(false);
    };

    const handleDocumentClick = (e) => {
        if (
            !e.target.closest('.select_custom') &&
            (propertyTypeOpen || propertyFeaturesOpen || budgetOpen || lifestyleOpen)  
        ) {
            closeAllDropdowns();
        }
    };
    

    document.addEventListener('click', handleDocumentClick);



    return (
        <div className="full_search_view">
            <div className="search-form search-property">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <form>
                            <div className="all_form_flex d-flex">
                    <div className="single_entry">
                        <div className="input-group mb-3">
                            <span className="input-group-text"><img src="/assets/location.svg" alt="Woovn Search" /></span>
                            <input type="text" className="form-control" placeholder="Beaverton, 97006" />
                        </div>
                    </div>
                    <div className="single_entry topp">
                     
                         <PropertyTypeDropdown
                                        isOpen={propertyTypeOpen}
                                        toggleDropdown={() => {
                                            setPropertyTypeOpen(!propertyTypeOpen);
                                            closeAllDropdowns();
                                        }}
                                    />   
                    </div>
                    <div className="single_entry topp">
                    <PropertyFeaturesDropdown
                                        isOpen={propertyFeaturesOpen}
                                        toggleDropdown={() => {
                                            setPropertyFeaturesOpen(!propertyFeaturesOpen);
                                            closeAllDropdowns();
                                        }}
                                    />    
                    </div>
                    <div className="single_entry topp">
                    <BudgetDropdown
                                        isOpen={budgetOpen}
                                        toggleDropdown={() => {
                                            setBudgetOpen(!budgetOpen);
                                            closeAllDropdowns();
                                        }}
                                    />
                    </div>
                    <div className="single_entry">
                        <div className="input-group mb-3">
                            <span className="input-group-text"><img src={SearchImage1} alt="property-features" /></span>
                            <input type="text" className="form-control" placeholder="Advanced Search (Optional)" />
                        </div>
                    </div>
                    <div className="single_entry last_entrty">
                    <LifeStyleSearchDropdown
        isOpen={lifestyleOpen}
        toggleDropdown={() => {
            setLifestyleOpen(!lifestyleOpen);
            closeAllDropdowns();
        }}
    />
                    </div>
                    <div className="single_entry">
                        <button type="submit" className="btn btn-primary w-100 custom_search">Search</button>
                    </div>
                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchForm;










