import React, { useState, useEffect, useRef } from 'react';
import '../../AboutusPages/AboutUsPages.css';
import Testimonial from '../../HomePage/Testimonial/Testimonial';
import Agents from '../../HomePage/Agents/Agents';
import CtaTwo from '../../HomePage/CtaTwo/CtaTwo';

import PropertySidebarForm from '../../PropertySidebarForm/PropertySidebarForm';
import Wovnn from '../../../public/assets/wovnn2.png';
import About1 from '../../../public/assets/about1.svg';
import About2 from '../../../public/assets/about3.svg';
import About3 from '../../../public/assets/about.svg';
import About4 from '../../../public/assets/abtus.png';
import Count1 from '../../../public/assets/count1.svg';
import Count2 from '../../../public/assets/count2.svg';
import Count3 from '../../../public/assets/count3.svg';
import Count4 from '../../../public/assets/count4.svg';



function AboutUs() {

    const [activeTab, setActiveTab] = useState('mission');

    /* counter-function */

    const [happyClientsCount, setHappyClientsCount] = useState(0);
    const [salesCount, setSalesCount] = useState(0);
    const [salesValue, setSalesValue] = useState(0);
    const [agentsCount, setAgentsCount] = useState(0);
    const [countStart, setCountStart] = useState(false);
    const counterSectionRef = useRef(null);


    useEffect(() => {
        // For Happy Clients
        const targetClientsCount = 24411;
        // For Sales
        const targetSalesCount = 50000; 
        // For Sales Value
        const targetSalesValue = 1800;  
        // For Agents
        const targetAgentsCount = 150;
    
        const duration = 3000; 
        let startTimestamp = null;
    
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    
            setHappyClientsCount(Math.floor(progress * targetClientsCount));
            setSalesCount(Math.floor(progress * targetSalesCount));
            setSalesValue(progress * targetSalesValue);
            setAgentsCount(Math.floor(progress * targetAgentsCount));
    
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
    
        window.requestAnimationFrame(step);
    }, []);
    
    function formatNumber(num) {
        if (num >= 1000) {
            let value = num / 1000;
            return value % 1 === 0 ? value + 'K+' : value.toFixed(1) + 'K+';
        }
        return num.toString();
    }
    
      /* counter-function */




    return (
        <div className="full_abt">
        <div className="abt_all">
        <div className="container">
        <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12">
          

            <div className="about_company">
                <h1>Welcome to Wovnn</h1>
                <img src={Wovnn} className="img-fluid" alt='aboutus'></img>
                <p>Welcome to the premier resource for all real estate information and services in the area. We hope you enjoy your visit and explore everything our realty website has to offer, including Aloha, Banks, Beaverton, Cornelius, Hillsboro, Newberg, North Plains, Portland, Rockaway Beach, Scappoose, Sherwood, Tigard, Tualatin, Warren, Garibaldi, Manzanita, Tillamook, Bethany, Cedar Hills, Cedar Mill and the Surrounding Areas real estate listings, information for homebuyers and sellers, and more About Us.</p>
                <p>Looking for a new home? Use Quick Search or Map Search to browse an up-to-date database list of all available properties in the area, or use our Dream Home Finder form and we'll conduct a personalized search for you.</p>
                <p>If you're planning to sell your home in the next few months, nothing is more important than knowing a fair asking price. We would love to help you with a FREE Market Analysis. We will use comparable sold listings to help you determine the accurate market value of your home.</p>

                <div className="float-icons1"> <img src={About1}  alt='aboutus icon'></img></div>

    </div>        
    
    
    
    
    </div>
     
<div className="col-lg-4  offset-lg-2 col-md-12 col-sm-12">

<div className="side_icon_form">
<div className="float-icons2"> <img src={About2}  alt='aboutus icon'></img></div>



<PropertySidebarForm />

<div className="float-icons3"> <img src={About3}  alt='aboutus icon'></img></div>
</div>
            
      


</div>
</div>
</div>
</div>




<div className="container">

    <div className="row">

        <div className="col-lg-6 col-md-12 col-sm-12">
        <img src={About4}  alt='aboutus icon' className="img-fluid"></img>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="abt_data">
            <h3>About Us</h3>
            <h2>Building Relationships, One Home at a Time</h2>
            <p>We're a team of experienced real estate experts with a deep understanding of housing market. Our mission is to provide you with unparalleled service, built on integrity, professionalism, and a commitment to your satisfaction.</p>
            </div>

            <div className="mission_vision">
      <div className="mission_vision_tab">
        <div 
          className={`left_mission ${activeTab === 'mission' ? 'active' : ''}`} 
          onClick={() => setActiveTab('mission')}>
            Mission
        </div>
        <div 
          className={`right_vision ${activeTab === 'vision' ? 'active' : ''}`} 
          onClick={() => setActiveTab('vision')}>
            Vision
        </div>
      </div>
      <div className='mission_vision_content'>
        {activeTab === 'mission' && (
          <div className="mission_content">We're a team of experienced real estate experts with a deep understanding of housing market. Our mission is to provide you with unparalleled service, built on integrity, professionalism, and a commitment to your satisfaction.</div>
        )}
        {activeTab === 'vision' && (
          <div className="vision_content">Looking for a new home? Use Quick Search or Map Search to browse an up-to-date database list of all available properties in the area, or use our Dream Home Finder form and we'll conduct a personalized search for you.</div>
        )}
      </div>
    </div>

            </div>
    </div>
</div>


<div className="full_counter">
<div className="container">
    <div className="row overall_abt_counter">
    <div className="col-lg-3 col-md-3 col-sm-6 counter">
    <img src={Count1} alt="Happy Clients Icon" />
    <h2>{happyClientsCount.toLocaleString()}</h2>
    <p>Happy Clients</p>
</div>
<div className="col-lg-3 col-md-3 col-sm-6 counter">
    <img src={Count2} alt="Sales Icon" />
    <h2>{formatNumber(salesCount)}</h2>

    <p> Sales</p>
</div>
<div className="col-lg-3 col-md-3 col-sm-6 counter">
    <img src={Count3} alt="Sales Value Icon" />
    <h2>${salesValue.toFixed(0)} M</h2>
    <p>Total Sales</p>
</div>
<div className="col-lg-3 col-md-3 col-sm-6 counter">
    <img src={Count4} alt="Agents Icon" />
    <h2>{agentsCount}</h2>
    <p>Agents</p>
</div>

    </div>
</div></div>


<div className="about_team">

 <Agents /> 
</div>



<div className="about_testimonials">

<Testimonial limit={3} />

</div>

<div className="about_newsletter">

<CtaTwo />
</div>




</div>





    );
}

export default AboutUs;

