

import React, { useState, useEffect, useRef } from 'react';
import './PropertySearchResult.css';

function PropertySearchResult() {
    const [blockCount, setBlockCount] = useState(9);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const rowRef = useRef(null);

    useEffect(() => {
        function handleBlockScroll() {
            const element = rowRef.current;
            
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                setIsAtBottom(true);
            }
        }
    
        if (rowRef.current) {
            rowRef.current.addEventListener('scroll', handleBlockScroll);
        }
    
        return () => {
            if (rowRef.current) {
                rowRef.current.removeEventListener('scroll', handleBlockScroll);
            }
        };
    }, []);
    

  

    useEffect(() => {
        if (isAtBottom) {
            setBlockCount(prevCount => prevCount + 3);
            setIsAtBottom(false);
        }
    }, [isAtBottom]);




    const [totalResults, setTotalResults] = useState(0); 
    useEffect(() => {
        fetch('yourApiEndpointHere').then(response => response.json()).then(data => {
            setTotalResults(data.total); 
        });
    }, []);
    

   

    return (
        <div className="searched_properties">
            <div className="container">
                <div className="row crow">
                <div className="col-lg-6">
                    <div className='search_headings'><h1>Open Houses</h1></div>
                </div>
                    <div className="col-lg-6">
                    <div className="blocks_counting">
    {`1-${blockCount} of ${totalResults} Results`}
</div>


                    </div>
                </div>
                <div className="row set_block" ref={rowRef}>
                    {Array.from({ length: blockCount }).map((_, idx) => (
                        <PropertyCard key={idx} />
                    ))}
                </div>
            </div>
        </div>
    );
}

function PropertyCard() {
  
    return (
        <div className="col-lg-4 col-md-4">
        <div className="property-card">
                            <div className="property-overlays d-flex">
                                <div className="left-overlays">
                                    <button className="featured">Featured</button>
                                    <button className="featured hot">Hot</button>
                                </div>
                                <div className="right-overlays d-flex">
                                    <div className="right_single_block d-flex">
                                        <div className="left_single_icon">
                                            <img src="/assets/camera.svg" alt="Camera icon" />
                                        </div>
                                        <div className="right_single_text">6</div>
                                    </div>
        
                                    <div className="right_single_block d-flex">
                                        <div className="left_single_icon">
                                            <img src="/assets/video.svg" alt="Video icon" />
                                        </div>
                                        <div className="right_single_text">1</div>
                                    </div>
        
                                    <div className="circle_heart d-flex">
                                        <div className="left_single_icon heart_set">
                                            <img src="/assets/heart.png" alt="Heart icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <img src="/assets/properties/property1.png" alt="Property" />
                            
                            <div className="bottom_property">
                                <h2>Home In Merric Way</h2>
                                <div className="location_flex d-flex">
                                    <div className="location_icon">
                                        <img src="/assets/blue-location.png" alt="Location icon" />
                                    </div>
                                    <div className="location_name">
                                        Merrick Way, Miami, FL 33134, USA
                                    </div>
                                </div>
        
                                <div className="price-amenities d-flex">
                                    <div className="price">
                                        <span className="price-sign">$</span> 540,000
                                    </div>
                                    <div className="amenities d-flex">
                                        <div className="single_amenities d-flex">
                                            <div className="left_amenities">
                                                <img src="/assets/amenties2.png" alt="Amenities icon" />
                                            </div>
                                            <div className="right_amenities">3</div>
                                        </div>
                                        <div className="single_amenities d-flex">
                                            <div className="left_amenities">
                                                <img src="/assets/amenties1.png" alt="Amenities icon" />
                                            </div>
                                            <div className="right_amenities">3</div>
                                        </div>
                                        <div className="single_amenities d-flex">
                                            <div className="left_amenities">
                                                <img src="/assets/amenties3.png" alt="Amenities icon" />
                                            </div>
                                            <div className="right_amenities">
                                                3000 <span className="area">sq ft</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
    );
}

export default PropertySearchResult;
