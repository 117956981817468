import React from 'react';
import './Agents.css';


function Agents() {
    return <div className="agent_list">

<div className="container">
<div className="row">
<div className="col-lg-12">
<h2>Our Agents</h2>

<div className="overall_agents d-flex">

<div className="agent_one">
<img src="/assets/agent.png" className="img-fluid" alt="agents" />
<h3>Vincent Fuller</h3>
<h4>Real Estate Agent , Country
House Real Estate</h4>
<p>Lorem ipsum dolor sit amet,
consectetur adipiscing elit.
Phasellus porta justo eget
risus consectetur,...</p>
<button className="view_agent">View Profile</button>

</div>

<div className="agent_one">
<img src="/assets/agent1.png" className="img-fluid" alt="agents" />
<h3>Brittany Watkins</h3>
<h4>Real Estate Agent , Country
House Real Estate</h4>
<p>Lorem ipsum dolor sit amet,
consectetur adipiscing elit.
Phasellus porta justo eget
risus consectetur,...</p>
<button className="view_agent">View Profile</button>

</div>



<div className="agent_one">
<img src="/assets/agent3.png" className="img-fluid" alt="agents" />
<h3>Michelle Ramirez</h3>
<h4>Real Estate Agent , Country
House Real Estate</h4>
<p>Lorem ipsum dolor sit amet,
consectetur adipiscing elit.
Phasellus porta justo eget
risus consectetur,...</p>
<button className="view_agent">View Profile</button>

</div>



<div className="agent_one">
<img src="/assets/agent4.png" className="img-fluid" alt="agents" />
<h3>Samuel Palmer</h3>
<h4>Real Estate Agent , Country
House Real Estate</h4>
<p>Lorem ipsum dolor sit amet,
consectetur adipiscing elit.
Phasellus porta justo eget
risus consectetur,...</p>
<button className="view_agent">View Profile</button>

</div>


</div>




</div>
</div>
</div>


    </div>;
}

export default Agents;
