import React, { useState } from 'react';
import './SearchProperty.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import SearchImage1 from '../../../public/assets/s1.svg';
import SearchImage2 from '../../../public/assets/s2.svg';

import PropertyTypeDropdown from '../../ReusableComponents/PropertyTypeDropdown/PropertyTypeDropdown';
import PropertyFeaturesDropdown from '../../ReusableComponents/PropertyFeaturesDropdown/PropertyFeaturesDropdown';
import BudgetDropdown from '../../ReusableComponents/BudgetDropdown/BudgetDropdown';
import LifeStyleSearchDropdown from '../../ReusableComponents/LifestyleSearchDropdown/LifeStyleSearchDropdown';


function SearchProperty() {
    const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
    const [propertyFeaturesOpen, setPropertyFeaturesOpen] = useState(false);
    const [budgetOpen, setBudgetOpen] = useState(false);
    const [lifestyleOpen, setLifestyleOpen] = useState(false);

    const closeAllDropdowns = () => {
        setPropertyTypeOpen(false);
        setPropertyFeaturesOpen(false);
        setBudgetOpen(false);
        setLifestyleOpen(false);
    };

    const handleDocumentClick = (e) => {
        if (
            !e.target.closest('.select_custom') &&
            (propertyTypeOpen || propertyFeaturesOpen || budgetOpen || lifestyleOpen)  
        ) {
            closeAllDropdowns();
        }
    };
    

    document.addEventListener('click', handleDocumentClick);
    
    return (

        <div className="container">
             <div className="row">
             <div className="col-lg-10 offset-lg-1">
        <div className="search-property">
            <form>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="input-group mb-3">
                            <span className="input-group-text"><img src="/assets/location.svg" alt="Woovn Search" /></span>
                            <input type="text" className="form-control" placeholder="Enter city or zip code" />
                        </div>
                    </div>
                    <div className="col-lg-3 ">
                    <PropertyTypeDropdown
                                        isOpen={propertyTypeOpen}
                                        toggleDropdown={() => {
                                            setPropertyTypeOpen(!propertyTypeOpen);
                                            closeAllDropdowns();
                                        }}
                                    />                       {/*  <div className="input-group mb-3">
                            <span className="input-group-text"><img src="/assets/building.svg" alt="Woovn Search" /></span>
                            <select className="form-select">
                                <option selected>Property Type</option>
                                <option value="1">House</option>
                                <option value="2">Apartment</option>
                                <option value="3">Condo</option>
                            </select>
                        </div> */}
                    </div>
                    <div className="col-lg-3  mtdn">
                    <PropertyFeaturesDropdown
                                        isOpen={propertyFeaturesOpen}
                                        toggleDropdown={() => {
                                            setPropertyFeaturesOpen(!propertyFeaturesOpen);
                                            closeAllDropdowns();
                                        }}
                                    />                       {/*  <div className="input-group mb-3">
                            <span className="input-group-text"><img src={SearchImage3} alt="property-features" /></span>
                            <select className="form-select">
                                <option selected>Property Features</option>
                                <option value="1">No Of Bedrooms</option>
                                <option value="2">Lot Size</option>
                                <option value="3">Square Feet</option>
                            </select>
                        </div> */}
                    </div>
                    <div className="col-lg-3  mtdn mtdn_btn">
                    <BudgetDropdown
                                        isOpen={budgetOpen}
                                        toggleDropdown={() => {
                                            setBudgetOpen(!budgetOpen);
                                            closeAllDropdowns();
                                        }}
                                    />
                    {/*     <div className="input-group mb-3">
                            <span className="input-group-text"><img src="/assets/dollar.svg" alt="Woovn Search" /></span>
                            <select className="form-select">
                                <option selected>Budget</option>
                                <option value="1">$1000 - $2000</option>
                                <option value="2">$2000 - $3000</option>
                                <option value="3">$3000 - $4000</option>
                            </select>
                        </div> */}
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-lg-5">
                        <div className="input-group mb-3">
                            <span className="input-group-text"><img src={SearchImage1} alt="advance-search" /></span>
                            <input type="text" className="form-control" placeholder="Advanced Search (Opional)" />
                        </div>
                    </div>
                    <div className="col-lg-5">
                       {/*  <div className="input-group mb-3">
                            <span className="input-group-text"><img src={SearchImage2} alt="advance-search" /></span>
                            <input type="text" className="form-control" placeholder="Lifestyle Search (Optional)" />
                        </div> */}
                     
    <LifeStyleSearchDropdown
        isOpen={lifestyleOpen}
        toggleDropdown={() => {
            setLifestyleOpen(!lifestyleOpen);
            closeAllDropdowns();
        }}
    />


                    </div>
                    <div className="col-lg-2">
                       {/*  <button type="submit" className="btn btn-primary w-100 custom_search">Search</button> */}
                        <Link to="/search-result" className="btn btn-primary w-100 custom_search">Search</Link>
                    </div>
                </div>
            </form>
        </div></div></div></div>
    );
}

export default SearchProperty;
