import React, { useState, useEffect, useRef } from 'react';
import './Preferences.css';
import Dollar from '../../../public/assets/dollar.svg';





function Dropdown({ placeholder, openDropdown, setOpenDropdown, type, selectedValue }) {
   

   

    return (
        <div className="dropdown-container" onClick={e => e.stopPropagation()}>
            <input
                type="text"
                className={`form-control`}
                placeholder={placeholder}
                value={selectedValue}
                
                onClick={() => setOpenDropdown(openDropdown === type ? null : type)}
            />
        </div>
    );
}

function Preferences() {
    const [isOpen, setIsOpen] = useState(false);
  /*   const [openDropdown, setOpenDropdown] = useState(null); */

  /* checkboxes */
  const [checkedState, setCheckedState] = useState(new Array(6).fill(false)); 
  
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
};

const checkboxLabels = [
    "New House (0-5 yrs.)",
    "Newer House (5-15 yrs.)",
    "Garage",
    "Pool",
    "Large Lot",
    "Near Park"
];
 /* checkboxes */

    
    const dropdownRef = useRef(null);




    const handleDropdownClick = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, []);

    
    
 
    

    return (
        <div className="select_custom" ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
            <div className="sel_icon"><img src={Dollar} alt="propertyType" /></div>
            <div className="sel_text">Preferences</div>

            {isOpen && (
      <div className="property_drop budget_drop preferences" onClick={handleDropdownClick}>
      <div className="checks_all">
          {checkboxLabels.map((label, index) => (
              <div className="custom-checkbox" key={index}>
                  <input
                      id={`six-checkbox-${index}`}
                      type="checkbox"
                      checked={checkedState[index]}
                      onChange={() => handleOnChange(index)}
                  />
                  <label htmlFor={`six-checkbox-${index}`}>
                      {label}
                  </label>
              </div>
          ))}
      </div>
  </div>
)}
</div>
);
}

export default Preferences;