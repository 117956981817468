


import React, { useState } from 'react';
import PropertyTypeDropdown from '../ReusableComponents/PropertyTypeDropdown/PropertyTypeDropdown';
import BudgetDropdown from '../ReusableComponents/BudgetDropdown/BudgetDropdown';





function PropertySidebarForm() {
    const [formData, setFormData] = useState({
        inquiryType: 'select',
        information: '',
        firstName: '',
        lastName: '',
        email: '',
        location: 'location1',
        zipcode: '',
        propertyType: 'type1',
        budget: 'budget1',
        gdprAgreement: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        
        console.log(formData);
        alert('Data ready to be sent: ' + JSON.stringify(formData));


        
    };


/* property-dropdown-data */
const [propertyTypeOpen, setPropertyTypeOpen] = useState(false);
const [propertyFeaturesOpen, setPropertyFeaturesOpen] = useState(false);
const [budgetOpen, setBudgetOpen] = useState(false);
const [lifestyleOpen, setLifestyleOpen] = useState(false);

const closeAllDropdowns = () => {
    setPropertyTypeOpen(false);
    setPropertyFeaturesOpen(false);
    setBudgetOpen(false);
    setLifestyleOpen(false);
};

const handleDocumentClick = (e) => {
    if (
        !e.target.closest('.select_custom') &&
        (propertyTypeOpen || propertyFeaturesOpen || budgetOpen || lifestyleOpen)  
    ) {
        closeAllDropdowns();
    }
};


document.addEventListener('click', handleDocumentClick);




    return (
        <div className="inquery property-detail-form">
            <h3>Real Estate Inquiry Form</h3>
            <form className="inq_form" onSubmit={handleSubmit}>
                
                <div className="mb-3 dnone">
                    <label htmlFor="inquiry-Type" className="form-label">Inquiry Type</label>
                    <select 
                        id="inquiry-Type" 
                        className="form-select"
                        name="inquiryType"
                        value={formData.inquiryType}
                        onChange={handleChange}
                    >
                        <option value="select">Select</option>
                        <option value="general">General</option>
                        <option value="basic">Basic</option>
                    </select>
                </div>
                
                <div className="mb-3">
                    <label htmlFor="information" className="form-label">Information</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="information" 
                        name="information"
                        placeholder="I’m a" 
                        value={formData.information}
                        onChange={handleChange}
                    />
                </div>
                
                <div className="row mb-3">
                    <div className="col">
                        <input 
                            type="text" 
                            className="form-control"
                            name="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col">
                        <input 
                            type="text" 
                            className="form-control"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                
                <div className="mb-3">
                    <input 
                        type="email" 
                        className="form-control" 
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">Location</label>
                            <select 
                                id="location" 
                                className="form-select"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                            >
                                <option value="location1">Location 1</option>
                                <option value="location2">Location 2</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3 marg_top">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="zipcode"
                                name="zipcode"
                                placeholder="Zip Code"
                                value={formData.zipcode}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="mb-3 ">
                            <label htmlFor="propertyType" className="form-label">Property</label>
                            <PropertyTypeDropdown
                                        isOpen={propertyTypeOpen}
                                        toggleDropdown={() => {
                                            setPropertyTypeOpen(!propertyTypeOpen);
                                            closeAllDropdowns();
                                        }}
                                    />     
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className="mb-3 marg_top">
                        <BudgetDropdown
                                        isOpen={budgetOpen}
                                        toggleDropdown={() => {
                                            setBudgetOpen(!budgetOpen);
                                            closeAllDropdowns();
                                        }}
                                    />
                        </div>
                    </div>
                </div>
                
                <label htmlFor="location" className="form-label dnone">GDPR Agreement</label>
                <div className="form-check mb-3 dnone">
                    <input 
                        type="checkbox" 
                        className="form-check-input" 
                        id="gdprAgreement"
                        name="gdprAgreement"
                        checked={formData.gdprAgreement}
                        onChange={handleChange}
                    />
                    <label className="form-check-label custom_agreement" htmlFor="gdprAgreement">I consent to having this website store my submitted information</label>
                </div>
                
                <div className="d-flex ">
                    <button type="submit" className="custom_btn">Submit</button>
                </div>
                
            </form>
        </div>
    );
}

export default PropertySidebarForm;
