import React from 'react';
import '../Forecloures/Forecloures.css'

import SearchForm from '../SearchByArea/SearchForm/SearchForm';
import PropertySearchResult from '../SearchByArea/PropertySearchResult/PropertySearchResult';










function SearchByArea() {
    return (
        <div className="searchview-container">
            <SearchForm />
          
            <PropertySearchResult />
           
          
        </div>
    );
}

export default SearchByArea;







