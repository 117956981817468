import React from 'react';
import '../Forecloures/Forecloures.css'

import SearchForm from '../Featured/SearchForm/SearchForm';
import Map from '../Featured/Map/Map';
import PropertySearchResult from '../Featured/PropertySearchResult/PropertySearchResult';










function Forecloures() {
    return (
        <div className="searchview-container">
            <SearchForm />
            <Map />
            <PropertySearchResult />
           
          
        </div>
    );
}

export default Forecloures;







