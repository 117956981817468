import React from 'react';
import '../Forecloures/Forecloures.css'
import '../..//SearchPages/SearchPages.css'

import SearchForm from '../MapSearch/SearchForm/SearchForm';
import PropertySearchResult from '../MapSearch/PropertySearchResult/PropertySearchResult';










function MapSearch() {
    return (
        <div className="searchview-container">
            <SearchForm />
          
            <PropertySearchResult />
           
          
        </div>
    );
}

export default MapSearch;







